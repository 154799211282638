import { useState } from 'react';
import { styled as styledMUI, Stack } from '@mui/material';
import BlurBanner from 'pages/dashboard/blurBanner';
import NoSubscriptionBanner from 'pages/dashboard/noSubscriptionBanner';
import ProtectionBadge from 'pages/dashboard/protectionBadge';

import CameraSnapshot from 'assets/camera-snapshot-placeholder.png';

import { TDeviceResponseType } from 'typings';

const OfferContainer = styledMUI(Stack)`
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: center;
  border-radius: 29px;
  width: 100%;
`;

function Device({
  maxHeight,
  snapshot_url,
  device,
  deviceLength
}: {
  maxHeight: string;
  snapshot_url: string | undefined;
  device: TDeviceResponseType;
  deviceLength: number;
}) {
  const [showNoSubscriptionBanner, setNoSubscriptionBanner] = useState(true);
  const showProtectionBadge = device?.existingSubscription;

  const handleClearClick = () => {
    setNoSubscriptionBanner(false);
  };

  return (
    <Stack
      key={device.id}
      sx={{
        position: 'relative',
        width: '100%',
        height: maxHeight,
        backgroundImage: `url(${snapshot_url || CameraSnapshot})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '20px',
        marginBottom: '20px',
        gap: deviceLength === 1 ? '105px' : '60px',
        justifyContent: !device.existingSubscription
          ? 'flex-start'
          : 'space-between'
      }}
    >
      <BlurBanner device={device} />
      {showProtectionBadge && (
        <OfferContainer>
          <ProtectionBadge
            skuname={device?.existingSubscription?.sku_name || ''}
          />
        </OfferContainer>
      )}

      {!showProtectionBadge && (
        <OfferContainer>
          {showNoSubscriptionBanner && (
            <NoSubscriptionBanner onClickCancel={handleClearClick} />
          )}
        </OfferContainer>
      )}
    </Stack>
  );
}

export default Device;
