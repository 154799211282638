import { useContext, useState } from 'react';
import { Stack, Button, Typography, Box } from '@mui/material';

import PopUp from 'components/PopUp';
import LaunchAppButton from 'components/LaunchAppButton';
import { useNavigate } from 'react-router-dom';
import { ConfigContext } from 'context';
import { getPartnerId } from 'utils/commonFunctions';

type TProps__PaymentConfirmed = {
  planName: string;
  planCycle: number;
};
function PaymentConfirmed(props: TProps__PaymentConfirmed) {
  const [isPopUpOpen, setIsPopUpOpen] = useState(true);
  const navigate = useNavigate();
  const config = useContext(ConfigContext);
  const PaymentSuccessIcon = config.paymentSuccessfull;
  const { planName, planCycle } = props;
  const partnerName = getPartnerId();
  console.log('partnerName; ', partnerName);
  const handlePopUpBackButtonClick = () => {
    setIsPopUpOpen(false);
    navigate('/subscriptions', {
      replace: true
    });
  };

  return (
    <PopUp isOpen={isPopUpOpen}>
      <>
        <Stack direction="column" alignItems="center" marginBottom="30px">
          <Stack marginBottom="12px">
            <PaymentSuccessIcon width="300" height="250" />
          </Stack>
          <Typography variant="h2" paddingBottom="12px">
            Payment Confirmed
          </Typography>
          {partnerName === 'instaview' && (
            <Box textAlign="center">
              <Typography
                variant="Body1Medium"
                padding="0px 12px"
                color="text.shadedText"
              >
                {`Thank you for your ${
                  planCycle === 1 ? 'Monthly' : 'Yearly'
                } ${planName} Plan subscription! Continue
              setting up your account, add cameras, and get started in the
              ${config.label} app.`}
              </Typography>
            </Box>
          )}
          {partnerName !== 'instaview' && (
            <Typography
              variant="Body1"
              style={{ marginTop: '10px', fontWeight: 700 }}
              color="secondary"
              fontSize={16}
              textAlign="center"
            >
              Thank You. Your payment invoices will be generated from
              InstaVision Inc.
            </Typography>
          )}
        </Stack>

        <Stack direction="column" alignItems="Stretch" marginBottom="6px">
          <LaunchAppButton />
          <Button
            variant="text"
            color="secondary"
            sx={{
              marginBottom: '16px',
              fontSize: (theme) => theme.typography.h3.fontSize,
              fontWeight: (theme) => theme.typography.h3.fontWeight,
              lineHeight: (theme) => theme.typography.h3.lineHeight
            }}
            onClick={() => handlePopUpBackButtonClick()}
          >
            Back
          </Button>
        </Stack>
      </>
    </PopUp>
  );
}
export default PaymentConfirmed;
