import styled from 'styled-components';
import DollarSymbol from 'assets/dollar-circle.png';
import CardSymbol from 'assets/card-coin.png';
import Camera from 'assets/camera_hd.png';
import { CUSTOM_THEME } from 'configs/theme';
import { Button } from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TopHeading = styled.div`
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.22px;
  text-align: center;
`;
const PlanSummary = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const PlanHeading = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
`;
const PlanItem = styled.div`
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 8px 0;
`;
const Image = styled.img``;
const CameraBlock = styled.div`
  background-color: ${CUSTOM_THEME.grayBackground};
  padding: 8px 20px 0px 20px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 10px;
`;
const LeftInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 100%;
  width: auto;
  gap: 10px;
`;
const HeadingText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${CUSTOM_THEME.primaryColor};
`;
const ModelText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;
const SubText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
`;
const RightImageContainer = styled.div`
  padding: 0px 10px;
`;
const BottomContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;
const TopContainer = styled.div`
  height: calc(100vh - 178px);
  overflow-y: auto;
`;
function SubscriptionSummary() {
  return (
    <Container>
      <TopContainer>
        <TopHeading>
          Home Camera at home is now covered by Basic Plan!
        </TopHeading>
        <PlanSummary>
          <PlanHeading>Plan summary</PlanHeading>
          <PlanItem>
            <Image src={DollarSymbol} alt="dolar-symbol" />
            Next Payment $3.99 On Nov 15,2023
          </PlanItem>
          <PlanItem>
            <Image src={CardSymbol} alt="dolar-symbol" />
            Billed Monthy
          </PlanItem>
        </PlanSummary>
        <CameraBlock>
          <LeftInfoContainer>
            <HeadingText>Camera 1</HeadingText>
            <ModelText>YI Outdoor 1080p</ModelText>
            <SubText>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="#B3E246" // Change the fill color here
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.66109 2.23657C9.85709 2.07381 10.1429 2.07381 10.3389 2.23657C12.2777 3.84642 14.7315 4.85747 17.4166 4.98601C17.658 4.99756 17.8641 5.17189 17.8957 5.41143C17.9645 5.93129 18 6.46164 18 7.00028C18 12.1627 14.7401 16.5637 10.1665 18.2572C10.0593 18.2969 9.94102 18.2969 9.83378 18.2572C5.26004 16.5638 2 12.1627 2 7.00016C2 6.46156 2.03548 5.93125 2.10424 5.41144C2.13593 5.17189 2.34202 4.99756 2.58337 4.98601C5.2685 4.85748 7.72231 3.84643 9.66109 2.23657ZM13.8566 8.19113C14.1002 7.85614 14.0261 7.38708 13.6911 7.14345C13.3561 6.89982 12.8871 6.97388 12.6434 7.30887L9.15969 12.099L7.28033 10.2197C6.98744 9.92678 6.51256 9.92678 6.21967 10.2197C5.92678 10.5126 5.92678 10.9874 6.21967 11.2803L8.71967 13.7803C8.87477 13.9354 9.08999 14.0149 9.30867 13.9977C9.52734 13.9805 9.72754 13.8685 9.85655 13.6911L13.8566 8.19113Z"
                />
              </svg>{' '}
              covered
            </SubText>
          </LeftInfoContainer>
          <RightImageContainer>
            <Image src={Camera} />
          </RightImageContainer>
        </CameraBlock>
      </TopContainer>
      <BottomContainer>
        <Button
          size="large"
          variant="contained"
          color="primary"
          style={{
            width: '90%'
          }}
        >
          Recent events
        </Button>
      </BottomContainer>
    </Container>
  );
}
export default SubscriptionSummary;
