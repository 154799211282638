import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Email from 'assets/email.png';
import Password from 'assets/lock.png';
import { fetchUser } from 'store/reducers/userReducers';
import { auth } from 'configs/firebaseConfig';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setRequestHeaders } from 'utils/commonFunctions';
import { leftNavData } from 'components/SideBar/SideBar';
import { Image } from 'styles';
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
import RemoveRedEyeSharpIcon from '@mui/icons-material/RemoveRedEyeSharp';
import { ConfigContext } from 'context';

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const config = useContext(ConfigContext);
  const LoginIllustration = config.loginIllustration;
  const Logo = config.logo;
  const onSignIn = async () => {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const token = await res.user.getIdToken();
      setRequestHeaders(token);
      dispatch(fetchUser());
    } catch (err) {
      setError(true);
      console.error(err);
    }
  };

  // const onGoogleSignIn = async () => {
  //   try {
  //     const res = await signInWithPopup(auth, googleProvider);
  //     const token = await res.user.getIdToken();
  //     setRequestHeaders(token);
  //     dispatch(fetchUser());
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // const onAppleSignIn = async () => {
  //   try {
  //     const res = await signInWithPopup(auth, appleProvider);
  //     const credential = OAuthProvider.credentialFromResult(
  //       res
  //     ) as OAuthCredential;
  //     const { accessToken } = credential;
  //     setRequestHeaders(`${accessToken}`);
  //     dispatch(fetchUser());
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    if (location.pathname.includes('authorize_device')) {
      return;
    }
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      navigate(`${leftNavData[0]['link']}`);
    }
  }, [isAuthenticated]);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      direction="row"
      height="100vh"
    >
      {!isMobile && (
        <Stack
          width="50%"
          justifyContent="center"
          alignItems="flex-start"
          pl="120px"
        >
          <LoginIllustration width="inherit" height="inherit" />

          <Typography variant="h1" mb="16px">
            {config.welcomeText}
          </Typography>
          <Typography variant="Body1SemiBold">
            Unlock a more powerful camera.
          </Typography>
        </Stack>
      )}
      <Stack
        width={isMobile ? '100%' : '50%'}
        justifyContent="center"
        alignItems="center"
      >
        <Card
          sx={{
            width: '70%',
            borderRadius: '20px',
            scrollBehavior: 'smooth',
            overflow: 'auto'
          }}
        >
          <Stack
            sx={{
              width: isMobile ? '100%' : '370px',
              margin: isMobile ? '32px auto' : '16px auto',
              padding: 2
            }}
          >
            <Stack direction="row" alignItems="center" mb={4}>
              <Logo width="60" height="60" />
              <Typography variant="h1" ml={8}>
                Sign In
              </Typography>
            </Stack>
            {/* <Typography>Sign in with Open accounts</Typography> */}
            {/* <Stack
              direction={isMobile ? 'column' : 'row'}
              alignItems="center"
              justifyContent="space-between"
              spacing={isMobile ? 2 : 0}
              mt={2}
            >
              <Button
                sx={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  width: isMobile ? '100%' : 'auto'
                }}
                variant="contained"
                onClick={onGoogleSignIn}
              >
                <Image
                  src={GoogleLogo}
                  width="24"
                  height="24"
                  style={{ marginRight: '10px' }}
                />{' '}
                Google
              </Button>
              <Button
                sx={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  width: isMobile ? '100%' : 'auto'
                }}
                variant="contained"
                onClick={onAppleSignIn}
              >
                <Image
                  src={AppleLogo}
                  width="24"
                  height="24"
                  style={{ marginRight: '10px' }}
                />{' '}
                Apple ID
              </Button>
            </Stack>
            <Divider sx={{ m: 4 }}>OR</Divider> */}
            {/* <Typography>Continue with email address</Typography> */}
            <Stack mt={2} mb={2}>
              <Typography variant="h3" mb={2}>
                EMAIL
              </Typography>
              <TextField
                type="text"
                placeholder="Your email"
                id="email"
                value={email}
                onChange={(e) => {
                  setError(false);
                  setEmail(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Image src={Email} width="24" height="24" />
                    </InputAdornment>
                  )
                }}
              />
            </Stack>
            <Stack mb={3}>
              <Typography variant="h3" mt={2.5} mb={2}>
                PASSWORD
              </Typography>
              <TextField
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                value={password}
                onChange={(e) => {
                  setError(false);
                  setPassword(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Image src={Password} width="24" height="24" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        className="btn-no-focus"
                        onClick={() =>
                          setShowPassword((prevState) => !prevState)
                        }
                        edge="end"
                      >
                        {showPassword ? (
                          <RemoveRedEyeSharpIcon />
                        ) : (
                          <VisibilityOffSharpIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {error ? (
                <div style={{ color: 'red' }}>
                  {' '}
                  Email or password is incorrect!
                </div>
              ) : (
                ''
              )}
            </Stack>

            <Button
              sx={{
                width: '100%'
              }}
              variant="contained"
              disabled={!(email && password)}
              onClick={onSignIn}
            >
              Sign In
            </Button>
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
}

export default LoginScreen;
