import { useContext, useState } from 'react';
import { Stack, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import PopUp from 'components/PopUp';
import LaunchAppButton from 'components/LaunchAppButton';
import { PRODUCT_NAME } from 'configs/constants';
import { ConfigContext } from 'context';

type TProps__TrailActivation = {
  isUpgrade?: boolean;
};

function TrialActivation(props: TProps__TrailActivation) {
  const navigate = useNavigate();
  const [isPopUpOpen, setIsPopUpOpen] = useState(true);

  const { isUpgrade = false } = props;

  const config = useContext(ConfigContext);
  const TrialActivationIcon = config.trialActivationIcon;
  return (
    <PopUp isOpen={isPopUpOpen}>
      <>
        <Stack direction="column" alignItems="center" marginBottom="30px">
          <Stack marginBottom="12px">
            <TrialActivationIcon width="300" height="250" />
          </Stack>
          <Typography variant="h2" textAlign="center">{`Free Trial ${
            isUpgrade ? 'Upgraded' : 'Activated'
          }`}</Typography>
          <Stack>
            <Typography
              variant="Body1"
              margin="12px 15px 0px 15px"
              textAlign="center"
            >
              {`Woohoo! You’ve successfully ${
                isUpgrade ? 'upgraded' : 'activated'
              } your free trial. Continue
              setting up your account, add cameras, and get started in the
              ${PRODUCT_NAME} app.`}
            </Typography>
          </Stack>
        </Stack>

        <Stack alignItems="Stretch">
          <LaunchAppButton />
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              setIsPopUpOpen(false);
              navigate('/subscriptions');
            }}
          >
            Back
          </Button>
        </Stack>
      </>
    </PopUp>
  );
}
export default TrialActivation;
