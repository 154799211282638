export const amplitudeEvents = {
  TRIAL_INFO: 'trial_info',
  CREDIT_CARD_INFO_SUCCESS: 'credit_card info_success',
  CREDIT_CARD_INFO_CANCEL: 'credit_card_info_cancel',
  TRIAL_SKIPPED: 'trial_skipped',
  TRIAL_SKIPPED_REASON: 'trial_skipped_reason',
  TRIAL_CANCEL: 'trial_cancel',
  TRIAL_CONFIRMED: 'trial_confirmed',
  TRIAL_INITIATED: 'trial_initiated',
  SUBSCRIPTION_MODIFY: 'subscription_modify',
  SUBSCRIPTION_BUY_NOW: 'subscription_buy_now',
  SUBSCRIPTION_CANCEL: 'subscription_cancel'
};
