/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-shorthand */

import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { useAppSelector } from 'store/hooks';
import { getPlanDetails } from 'services/api/plansApi';
import { TPlanList, TPurchasePayload, TSubscriptionType } from 'typings';
import { endpoints } from 'services/api/endpoints';

import PlanCycleSwitcher from 'components/PlanCycleSwitcher';
import PlansCarousel from 'components/PlansCarousel';
import InstaSpinner from 'components/InstaSpinner';

import { getSubscriptionDetails } from 'services/api/subscriptionApi';
import { FREE_TRIAL_DURATION } from 'utils/constants';

// type TProps__UpgradeSubscription = {};

function UpgradeSubscription() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [allPlans, setAllPlans] = useState<TPlanList[]>([]);
  const [currentSubscription, setCurrentSubscription] =
    useState<TSubscriptionType>();
  const [selectedCyclesPlans, setSelectedCyclesPlans] = useState<TPlanList[]>(
    []
  );

  const spaceData = useAppSelector((state) => state.space.spaceData);

  const { id: subscriptionId } = useParams();
  const { isFreeTrial, isUpgrade, deviceId } = location.state;

  // const getSupportedCameraCount = (supported_features: any) => {
  //   return supported_features
  //     .filter((item: any) => item.id.includes('camera_supported'))[0]
  //     .id.split('_')[0];
  // };

  // const filterByDeviceSupportCount = (el: TPlanList) => {
  //   const supportedCameraCount = getSupportedCameraCount(el.supported_features);
  //   console.log(supportedCameraCount);

  //   return supportedCameraCount === 'unlimited'
  //     ? 100
  //     : Number(supportedCameraCount);
  // };

  const findCurrentPlanLevel = () => {
    let planLevel = 0;
    // checking for level wrt current subscription
    allPlans?.forEach((el: TPlanList) => {
      if (el?.id === currentSubscription?.sku_id) {
        planLevel = Number(el.level);
      }
    });
    return planLevel;
  };

  const findCurrentPlanSubscriptionCycle = () => {
    let subscriptionCycle = 0;
    allPlans?.forEach((el: TPlanList) => {
      if (el?.id === currentSubscription?.sku_id) {
        subscriptionCycle = Number(el.subscription_cycle);
      }
    });
    return subscriptionCycle;
  };

  const currentPlanLevel = currentSubscription ? findCurrentPlanLevel() : 0;
  const currentSubscriptionCycle = currentSubscription
    ? findCurrentPlanSubscriptionCycle()
    : 1;

  const filterByLevel = (el: TPlanList) => {
    /*
      If All plans (current plan) subscription cycle is greater then current subscription cycle
     */
    if (el.subscription_cycle > currentSubscriptionCycle) {
      /*
        Return true if All plans (current plan) is yearly then we check for all plans current level is greater than or equal to current plan level
       */
      if (el.level >= currentPlanLevel) {
        return true;
      }
      /*
        Return true if All plans (current plan) is yearly then we check for all plans current level is lesser than to current plan level
       */
      if (el.level < currentPlanLevel) {
        return false;
      }
    }
    if (el.subscription_cycle === currentSubscriptionCycle) {
      if (el.level > currentPlanLevel) {
        return true;
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    if (spaceData[0]?.id && !isLoading) {
      // debugger
      setIsLoading(true);

      Promise.all([getPlanDetails(), getSubscriptionDetails()])
        .then((res) => {
          const [allPlansResponse, subscriptionDetails] = res;

          const existingSubscripiton = subscriptionDetails.data.find(
            (subscriptionDetail) => {
              return subscriptionDetail.id === subscriptionId;
            }
          );
          // debugger;
          console.log('subscriptionDetails filtered', existingSubscripiton);
          setAllPlans(allPlansResponse.data);
          if (existingSubscripiton) {
            setCurrentSubscription(existingSubscripiton);
          }
        })
        .catch((err) => {
          // debugger;
          console.log('err', err);
          setIsLoading(false);
        })
        .finally(() => {});
    }
  }, []);

  const filterPlansForCycle = (isYearlyCycle: boolean) => {
    const cycleMonths = isYearlyCycle ? 12 : 1;
    const cyclePlans = allPlans.filter(
      (el: TPlanList) => el.subscription_cycle === cycleMonths
    );
    // debugger
    cyclePlans.sort(
      (a, b) => b.prices[0].original_price - a.prices[0].original_price
    );
    setSelectedCyclesPlans(cyclePlans);
    setIsLoading(false);
  };

  useEffect(() => {
    if (allPlans.length) {
      filterPlansForCycle(true);
    }
  }, [allPlans]);

  const createPurchaseId = (plan: TPlanList) => {
    // debugger;
    const payload: TPurchasePayload = {
      country: 'US',
      sku_id: plan.id,
      trial: isFreeTrial,
      space_id: spaceData[0]?.id,
      action: 'Upgrade',
      previous_purchase_id: currentSubscription?.id
    };

    // if (subscriptionData.length > 0) {
    //   payload['action'] = 'Upgrade';
    //   payload['previous_purchase_id'] = subscriptionData[0].id;
    // }

    axios
      .post(endpoints.createPurchaseIdUrl, payload)
      .then((res) => {
        navigate('/reviewPurchase', {
          state: {
            isUpgrade: isUpgrade,
            isFreeTrial: isFreeTrial,
            planSelected: plan,
            purchaseId: res.data.purchase_id,
            deviceId
          }
        });
      })
      .catch((err) => console.log('purchases err', err));
  };

  const handlePlanSelection = (el: TPlanList) => {
    // debugger;
    // console.log('handlePlanSelection', el);
    // console.log(
    //   'isAnyActiveNonLinkedSubscription ',
    //   isAnyActiveNonLinkedSubscription
    // );

    // if (!pairingFlow && isAnyActiveNonLinkedSubscription && deviceId) {

    // addCameraToSubscription(isAnyActiveNonLinkedSubscription);
    // } else {

    createPurchaseId(el);
    // }
  };

  if (isLoading) {
    return <InstaSpinner withWrapper />;
  }

  if (!isLoading && selectedCyclesPlans.length) {
    return (
      <Stack pt={2.5}>
        {isFreeTrial && !isUpgrade && (
          <Typography
            variant="h2"
            sx={{ textTransform: 'capitalize', textAlign: 'center' }}
          >
            {`Choose your ${FREE_TRIAL_DURATION} days Free Trial plan`}
            {/* Choose your 15 days Free Trial plan */}
          </Typography>
        )}
        {isUpgrade && currentSubscription?.trial && (
          <Typography
            variant="h2"
            sx={{ textTransform: 'capitalize', textAlign: 'center' }}
          >
            Choose the free trial plan Upgrade
          </Typography>
        )}
        {isUpgrade && !currentSubscription?.trial && (
          <Typography
            variant="h2"
            sx={{ textTransform: 'capitalize', textAlign: 'center' }}
          >
            Upgrade the plan that fits your needs
          </Typography>
        )}
        {!isFreeTrial && !isUpgrade && (
          <Typography
            variant="h2"
            sx={{ textTransform: 'capitalize', textAlign: 'center' }}
          >
            Choose the plan that fits your needs
          </Typography>
        )}

        <Typography
          variant="Body1Medium"
          mt={1.5}
          mb={2}
          color="text.shadedText"
          textAlign="center"
        >
          You can choose any of the plans offered and subscribe to be able to
          choose more than one camera
        </Typography>

        {!isLoading &&
          allPlans.length > 0 &&
          selectedCyclesPlans.length > 0 && (
            <>
              <PlanCycleSwitcher onChange={filterPlansForCycle} />
              <PlansCarousel
                plansData={selectedCyclesPlans}
                currentPlan={currentSubscription}
                allowSelection
                onPlanSelection={handlePlanSelection}
                filterByLevel={filterByLevel}
              />
            </>
          )}
      </Stack>
    );
  }
  return null;
}

export default UpgradeSubscription;
