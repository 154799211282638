import { Stack, Button, Typography } from '@mui/material';
import PopUp from 'components/PopUp';
import { ConfigContext } from 'context';
import { useContext } from 'react';
import { PopupTypes } from 'typings/popUpTypes';

function CustomerService({
  isCancelModalOpen,
  onSubscriptionDoneClick
}: {
  isCancelModalOpen: boolean;
  onSubscriptionDoneClick: () => unknown;
}) {
  const config = useContext(ConfigContext);
  return (
    <PopUp type={PopupTypes.TYPE2} isOpen={isCancelModalOpen}>
      <>
        <Stack
          direction="column"
          alignItems="center"
          marginTop="61px"
          textAlign="center"
          marginBottom="30px"
        >
          <Typography variant="h2" marginBottom="20px">
            Contact customer service
          </Typography>

          <Stack>
            <Typography variant="Body1Medium" sx={{ marginBottom: '12px' }}>
              If you need help, or would like to modify your subscription,
              please call customer service
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              {config.phoneNumber}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="column" alignItems="stretch" marginBottom="6px">
          <Button
            variant="contained"
            color="inherit"
            onClick={onSubscriptionDoneClick}
          >
            Done
          </Button>
        </Stack>
      </>
    </PopUp>
  );
}
export default CustomerService;
