import { Stack, Typography } from '@mui/material';
import { ReactComponent as WifiIcon } from 'assets/wifi-icon.svg';
import { ReactComponent as LocationIcon } from 'assets/location.svg';
import { ReactComponent as FirmwareVersionIcon } from 'assets/firmware-version-icon.svg';
import { ReactComponent as CameraIcon } from 'assets/camera-icon.svg';
import { ReactComponent as TimeIcon } from 'assets/clock.svg';
import InstaCard from 'components/InstaCard';
import { TDeviceResponseType } from 'typings';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';

function CameraDetails({ device }: { device: TDeviceResponseType }) {
  return (
    <>
      <Typography
        variant={`${isMobile ? 'h5' : 'h3'}`}
        paddingBottom="16px"
        textAlign="center"
      >
        {device.name}
      </Typography>

      <InstaCard>
        <Stack paddingBottom="20px" gap="5px">
          <Stack direction="row" alignContent="center">
            <WifiIcon />
            <Typography
              variant={`${isMobile ? 'h6' : 'h4'}`}
              paddingLeft="20px"
            >
              Wifi
            </Typography>
          </Stack>
          <Typography
            variant="Body1"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {device.device_state.wifi_name}
          </Typography>
        </Stack>

        <Stack padding="20px 0px" gap="5px">
          <Stack direction="row" alignContent="center">
            <LocationIcon />
            <Typography
              variant={`${isMobile ? 'h6' : 'h4'}`}
              paddingLeft="20px"
            >
              Location
            </Typography>
          </Stack>
          <Typography
            variant="Body1"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {device.location}
          </Typography>
        </Stack>

        <Stack padding="20px 0px" gap="5px">
          <Stack direction="row" alignContent="center">
            <FirmwareVersionIcon />
            <Typography
              variant={`${isMobile ? 'h6' : 'h4'}`}
              paddingLeft="20px"
            >
              Firmware Version
            </Typography>
          </Stack>
          <Typography
            variant="Body1"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {device.device_state.firmware_version}
          </Typography>
        </Stack>

        <Stack padding="20px 0px" gap="5px">
          <Stack direction="row" alignContent="center">
            <CameraIcon />
            <Typography
              variant={`${isMobile ? 'h6' : 'h4'}`}
              paddingLeft="20px"
            >
              Model
            </Typography>
          </Stack>
          <Typography
            variant="Body1"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {device.model_name}
          </Typography>
        </Stack>

        <Stack paddingTop="20px" gap="5px">
          <Stack direction="row" alignContent="center">
            <TimeIcon />
            <Typography
              variant={`${isMobile ? 'h6' : 'h4'}`}
              paddingLeft="20px"
            >
              Last Active Time
            </Typography>
          </Stack>
          <Typography
            variant="Body1"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {dayjs(device.device_state.last_seen).format('DD.MM.YY - h:mm A')}
          </Typography>
        </Stack>
      </InstaCard>
    </>
  );
}
export default CameraDetails;
