/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CURRENCY } from 'configs/constants';
import styled from 'styled-components';
import PaymentScreenDemo from 'views/PaymentScreen/PaymentScreen.demo';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Image, Flex } from 'styles';
import PaymentSuccess from 'assets/payment-sucess.png';
import PaymentFailure from 'assets/payment-fail.png';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Card, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BuyUserSubscription,
  getSubscriptionDetails,
  UpgradeUserSubscription
} from 'services/api/subscriptionApi';
import { GetPaymentMethods } from 'services/api/paymentsApi';
import { IsMobile } from 'hooks/isMobile';
import { endpoints } from 'services/api/endpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CameraIcon from 'assets/camera_icon.png';
import Alarm from 'assets/alarm.png';
import Notification from 'assets/notification-bing.png';
import CloudAdd from 'assets/cloud-add.png';
import Monitoring from 'assets/eye.png';
import Sharing from 'assets/people.png';
import Support from 'assets/support.png';
import CloudStorage from 'assets/cloud-storage.png';
import VideoCameraIcon from 'assets/video-camera.png';
import Spinner from 'components/Spinner';
import {
  TDeviceResponseType,
  TStatusMessages,
  TProps_PaymentOptions,
  TSupportedFeature,
  TPaymentMethods
} from 'typings';
import { ConfigContext } from 'context';
import { getCardImageProps } from 'utils/commonFunctions';
import { FREE_TRIAL_DURATION } from 'utils/constants';

dayjs.extend(relativeTime);

const ActivaPlan = styled.div`
  color: #5fa4fb;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  width: ${IsMobile() ? 'auto' : '33%'};
`;

const PlanHeading = styled.div`
  color: #484c4f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const CameraCount = styled.div`
  color: #388efa;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 12px;
  text-transform: capitalize;
`;

const ExistingPlanHeading = styled(PlanHeading)`
  color: #9ca8b0;
`;

const PlanPrice = styled.div`
  color: #484c4f;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const ReadMore = styled(PlanPrice)`
  width: 100%;
  color: #388efa;
  cursor: pointer;
`;

const TotalInfo = styled.div`
  color: #000;
  width: ${IsMobile() ? 'auto' : '33%'};

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 5px;
`;

const Info = styled.div`
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.22px;
  margin-bottom: 10px;
`;

const PlanUsedInfo = styled.div`
  color: #9ca8b0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  text-align: center;
`;

const PlanDetailPoint = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 10px;
`;

const obj: TStatusMessages = {
  basic_features: CameraIcon,
  user_sharing: Sharing,
  enhanced_motion: Notification,
  '30_days_event': CloudStorage,
  enhanced_support: Support,
  self_monitoring_send_help: Alarm,
  active_monitoring: Monitoring,
  '6_months_alarm': CloudAdd,
  unlimited_camera_supported: VideoCameraIcon,
  '1_camera_supported': VideoCameraIcon
};

export default function PaymentOptions(props: TProps_PaymentOptions) {
  const location = useLocation();
  const {
    planSelected,
    purchaseId,
    toggleModal,
    isUpgrade,
    isFreeTrial,
    deviceId
  } = Object.keys(props).length > 0 ? props : location.state;

  // debugger;
  const [purchaseStatus, setPurchaseStatus] = useState<boolean | null>(null);
  const [paymentOption, setPaymentOption] = useState<TPaymentMethods[]>([]);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isMobile = IsMobile();
  const [openPlanDetail, setOpenPlanDetails] = useState(false);
  const [paymentOptionSelected, setPaymentOptionSelected] =
    useState<string>('');
  const navigate = useNavigate();
  const [selectedSpaceDeviceData, setSelectedSpaceDeviceData] = useState<
    TDeviceResponseType[]
  >([]);
  const spaceData = useAppSelector((state) => state.space.spaceData);
  const subscriptionData = useAppSelector(
    (state) => state.subscription.subscriptionData[0]
  );
  const [pollingCount, setPollingCount] = useState(0);
  const [proRataPrice, setProRataPrice] = useState(null);
  const handleChange = (e: { target: { value: string } }) => {
    setPaymentOptionSelected(e.target.value);
  };
  const config = useContext(ConfigContext);

  const getSupportedCameraCount = (supported_features: any) => {
    return supported_features
      .filter((item: any) => item.id.includes('camera_supported'))[0]
      .id.split('_')[0];
  };

  const getSupportedCameraCountNumber = (supportedFeatures: any) => {
    const cameraSupportedCount = supportedFeatures
      .find((item: any) => item.id.includes('camera_supported'))
      ?.id.split('_')[0];

    return cameraSupportedCount === 'unlimited' ? 99 : 1;
  };

  const fetchAllDevices = () => {
    axios
      .get(endpoints.getAllDeviceBySpaceIdUrl(spaceData[0].id || ''))
      .then((res) => {
        setSelectedSpaceDeviceData(res.data.items);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchProRataDetails = () => {
    axios
      .get(endpoints.paymentOptions(purchaseId))
      .then((res) => {
        setProRataPrice(res.data.upgrade_price);
      })
      .catch((err) => {
        console.log('fetchProRataDetails ERR', err);
      });
  };

  useEffect(() => {
    fetchAllDevices();
    if (isUpgrade) {
      fetchProRataDetails();
    }

    GetPaymentMethods()
      .then((res) => {
        setPaymentOption(res.data.payment_method || []);
        if (res.data?.payment_method?.length > 0) {
          setPaymentOptionSelected(res?.data?.payment_method[0]['id']);
        }
      })
      .catch((err) => console.log('err payment_methods', err));

    return () => {
      dispatch(fetchAllSubscription());
    };
  }, []);

  const authenticateDevice = () => {
    // debugger;

    axios
      .patch(endpoints.authenticateDevice(spaceData[0].id, deviceId), {
        pairing_status: 'Activated'
      })
      .then((res) => {
        console.log('authenticateDevice success', res);
      })
      .catch((err) => {
        console.log('authenticateDevice err->>', err);
      });
  };

  const addCameraToSubscription = async (subscriptionObj: any) => {
    const supportedCameraCount = getSupportedCameraCountNumber(
      subscriptionObj.supported_features
    );
    let payload = {};

    if (isUpgrade) {
      const device_ids = selectedSpaceDeviceData.map((el) => el?.id);

      payload = {
        device_ids
      };
    } else {
      const deviceArray = selectedSpaceDeviceData.map((el) => el?.id);
      if (supportedCameraCount > 1) {
        const device_ids = deviceArray;
        payload = {
          device_ids
        };
      } else {
        payload = {
          device_ids: [deviceId || deviceArray[0]]
        };
      }
    }
    let errorMsg = '';

    axios
      .put(endpoints.addDevice(subscriptionObj.id), payload)
      .then((res) => {
        console.log('addCameraToSubscription success', res);
        dispatch(fetchAllSubscription());
      })
      .catch((err) => {
        console.log('addCameraToSubscription err->>', err);
        errorMsg =
          'Sorry! We cannot add the device to your selected to your subscription plan';
        setErrorMessage(
          'Sorry! We cannot add the device to your selected to your subscription plan'
        );
        console.log('addCameraToSubscription', err);
      })
      .finally(() => {
        setIsLoading(false);
        setPurchaseStatus(true);
        // if (IsMobile()) {
        navigate('/payment-success', {
          state: {
            isFreeTrial,
            isUpgrade,
            errorMessage: errorMsg,
            showSetupComplete: Boolean(deviceId)
          }
        });
        // }
      });
    if (deviceId) {
      // debugger;
      authenticateDevice();
    }
  };

  const findSubscriptionByPurchaseId = (subscriptions: any) => {
    const found = subscriptions.filter((item: any) => item.id === purchaseId);
    if (found.length) {
      return found[0];
    }
    return false;
  };

  const polling = () => {
    setTimeout(() => {
      getSubscriptionDetails()
        .then((res) => {
          // debugger;

          const found = findSubscriptionByPurchaseId(res.data);
          // debugger;

          if (found && found.id === purchaseId) {
            // debugger;
            addCameraToSubscription(found);
          } else {
            // debugger;
            console.log('pollingCount', pollingCount, Date.now());
            setPollingCount((prevState) => {
              if (prevState < 5) {
                polling();
              } else {
                setPurchaseStatus(false);
                setIsLoading(false);
              }
              return prevState + 1;
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setPurchaseStatus(false);
          console.log('getSubscriptionDetails ERROR', err);
        });
    }, 1000);
  };

  const submitPurchaseRequest = (value?: string | null) => {
    // debugger;

    setIsLoading(true);
    const payload = {
      purchase_id: purchaseId as string,
      stripe_payment_method: value || paymentOptionSelected
    };
    const startPurchase = isUpgrade
      ? UpgradeUserSubscription
      : BuyUserSubscription;

    startPurchase(payload)
      .then((res) => {
        if (res.data.status === 'succeeded' || res.data.status === '') {
          // debugger;

          polling();
        } else {
          setPurchaseStatus(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setPurchaseStatus(false);
        setIsLoading(false);
        console.log('err submitPurchaseRequest', err);
      });
  };

  useEffect(() => {
    console.log('detech change', planSelected);
  }, [planSelected?.id]);

  const { name, prices } = planSelected || {};
  const { currency, price } = (Array.isArray(prices) && prices[0]) || {
    currency: 'USD'
  };
  if (subscriptionData === undefined) {
    <Flex style={{ position: 'fixed', top: '50%', left: '50%' }}>
      <CircularProgress />
    </Flex>;
  }
  let refDate;
  let today;
  let diff;
  if (subscriptionData) {
    refDate = subscriptionData?.trial
      ? dayjs(subscriptionData.trial_start_date)
      : dayjs(subscriptionData.pay_date);
    today = dayjs(new Date());
    diff = today.diff(dayjs(refDate), 'day', true);
    diff = diff < 1 ? Math.ceil(diff) : Math.floor(diff);
  } else {
    today = dayjs(new Date());
    refDate = '';
    diff = '';
  }

  if (purchaseStatus == null) {
    return (
      <div style={{ overflowY: 'scroll' }}>
        {isLoading && (
          <Flex style={{ position: 'fixed', top: '50%', left: '50%' }}>
            <CircularProgress />
          </Flex>
        )}
        <Typography>Review your purchase</Typography>
        <Typography>
          You wont be charged till your{' '}
          {isFreeTrial ? 'Free trial' : 'subscription'} {isUpgrade && 'upgrade'}{' '}
          ends. You can cancel any time before and you will not be charged
        </Typography>
        <Card
          sx={{
            boxShadow: 'none',
            backgroundColor: '#F0F4F4',
            padding: '8px',
            borderRadius: '20px',
            margin: '16px 0'
          }}
        >
          <Card
            sx={{
              boxShadow: 'none',
              padding: '12px',
              borderRadius: '12px'
            }}
          >
            {isUpgrade && proRataPrice !== null && (
              <>
                <Flex justify="space-between">
                  <Flex
                    direction="column"
                    align="baseline"
                    style={{ width: IsMobile() ? 'auto' : '33%' }}
                  >
                    <ExistingPlanHeading>
                      Current {subscriptionData.sku_name}
                      {subscriptionData.subscription_cycle === 1
                        ? ' Monthly '
                        : ' Yearly '}
                    </ExistingPlanHeading>
                    {isMobile && subscriptionData && (
                      <PlanUsedInfo>
                        {diff} {diff === 1 ? 'day' : 'days'} used of your{' '}
                        {subscriptionData.subscription_cycle === 1
                          ? 'monthly '
                          : 'yearly '}
                        plan
                      </PlanUsedInfo>
                    )}
                  </Flex>
                  {!isMobile && subscriptionData && (
                    <PlanUsedInfo>
                      {diff} {diff === 1 ? 'day' : 'days'} used of your{' '}
                      {subscriptionData.subscription_cycle === 1
                        ? 'monthly '
                        : 'yearly '}
                      plan
                    </PlanUsedInfo>
                  )}
                  <Flex
                    direction="column"
                    align="end"
                    style={{ width: IsMobile() ? 'auto' : '33%' }}
                  >
                    <PlanPrice>
                      <div>
                        {CURRENCY[subscriptionData.currency]}
                        {Number(
                          subscriptionData.paid_amount === 0
                            ? 0
                            : price - proRataPrice
                        ) / 100}
                      </div>
                    </PlanPrice>
                    <ReadMore
                      onClick={() =>
                        setOpenPlanDetails((prevState) => !prevState)
                      }
                    >
                      Read {openPlanDetail ? 'less' : 'more'}
                    </ReadMore>
                  </Flex>
                </Flex>
                {openPlanDetail && (
                  <div style={{ marginTop: 10 }}>
                    <Flex wrap="wrap" justify="space-between">
                      {subscriptionData.supported_features.map(
                        (element: TSupportedFeature) => {
                          const icon = obj[element.id];
                          return (
                            <Flex
                              align="center"
                              key={element.id}
                              mbottom="10"
                              style={{ width: isMobile ? '100%' : '50%' }}
                              wrap="wrap"
                            >
                              <Image width="20" height="20" src={icon} />
                              <PlanDetailPoint>
                                {element.description}
                              </PlanDetailPoint>
                            </Flex>
                          );
                        }
                      )}
                    </Flex>
                    <CameraCount>
                      {`${getSupportedCameraCount(
                        subscriptionData.supported_features
                      )} ${
                        getSupportedCameraCount(
                          subscriptionData.supported_features
                        ) === 1
                          ? 'camera only'
                          : 'cameras'
                      }`}
                    </CameraCount>
                  </div>
                )}
                <hr />
              </>
            )}

            <Flex justify="space-between">
              <Flex
                direction="column"
                align="baseline"
                style={{ width: IsMobile() ? 'auto' : '33%' }}
              >
                <PlanHeading>
                  {name}
                  {planSelected.subscription_cycle === 1
                    ? ' Monthly '
                    : ' Yearly '}
                </PlanHeading>
                {isMobile && isFreeTrial && (
                  <ActivaPlan>
                    Activated free trial for{' '}
                    {isUpgrade
                      ? FREE_TRIAL_DURATION - Number(diff)
                      : `${FREE_TRIAL_DURATION}`}{' '}
                    days
                  </ActivaPlan>
                )}
              </Flex>
              {!isMobile && isFreeTrial && (
                <ActivaPlan>
                  Activated free trial for{' '}
                  {isUpgrade
                    ? FREE_TRIAL_DURATION - Number(diff)
                    : `${FREE_TRIAL_DURATION}`}{' '}
                  days
                </ActivaPlan>
              )}
              <Flex
                direction="column"
                align="end"
                style={{ width: IsMobile() ? 'auto' : '33%' }}
              >
                <PlanPrice>
                  {' '}
                  {CURRENCY[currency]}
                  {Number(price) / 100}
                </PlanPrice>
                <ReadMore
                  onClick={() => setOpenPlanDetails((prevState) => !prevState)}
                >
                  Read {openPlanDetail ? 'less' : 'more'}
                </ReadMore>
              </Flex>
            </Flex>
            {openPlanDetail && (
              <div style={{ marginTop: 10 }}>
                <Flex wrap="wrap" justify="space-between">
                  {planSelected.supported_features.map(
                    (element: TSupportedFeature) => {
                      const icon = obj[element.id];
                      return (
                        <Flex
                          align="center"
                          key={element.id}
                          mbottom="10"
                          style={{ width: isMobile ? '100%' : '50%' }}
                          wrap="wrap"
                        >
                          <Image width="20" height="20" src={icon} />
                          <PlanDetailPoint>
                            {element.description}
                          </PlanDetailPoint>
                        </Flex>
                      );
                    }
                  )}
                </Flex>
                <CameraCount>
                  {`${getSupportedCameraCount(
                    planSelected.supported_features
                  )} ${
                    getSupportedCameraCount(planSelected.supported_features) ===
                    1
                      ? 'camera only'
                      : 'cameras'
                  }`}
                </CameraCount>
              </div>
            )}
            <hr />
            <Flex justify="space-between">
              <Flex
                direction="column"
                align="baseline"
                style={{ width: IsMobile() ? 'auto' : '33%' }}
              >
                <TotalInfo>Total</TotalInfo>
                {isMobile && isFreeTrial && (
                  <PlanUsedInfo>
                    You will be charged on{' '}
                    {isUpgrade
                      ? dayjs(subscriptionData?.next_billing_date).format(
                          'YYYY/MM/DD'
                        )
                      : dayjs(new Date())
                          .add(FREE_TRIAL_DURATION, 'day')
                          .format('YYYY/MM/DD')}
                  </PlanUsedInfo>
                )}
              </Flex>

              {!isMobile && isFreeTrial && (
                <PlanUsedInfo>
                  You will be charged on{' '}
                  {isUpgrade
                    ? dayjs(subscriptionData?.next_billing_date).format(
                        'YYYY/MM/DD'
                      )
                    : dayjs(new Date())
                        .add(FREE_TRIAL_DURATION, 'day')
                        .format('YYYY/MM/DD')}
                </PlanUsedInfo>
              )}

              {!isFreeTrial && isUpgrade && proRataPrice !== null ? (
                <Flex style={{ width: '33%', justifyContent: 'end' }}>
                  <PlanPrice
                    style={{ textDecoration: 'line-through', width: 'auto' }}
                  >
                    {' '}
                    {CURRENCY[currency]}
                    {Number(price) / 100}
                  </PlanPrice>
                  <TotalInfo style={{ width: 'auto' }}>
                    {' '}
                    {CURRENCY[currency]}
                    {Number(proRataPrice) / 100}
                  </TotalInfo>
                </Flex>
              ) : (
                <TotalInfo style={{ textAlign: 'right' }}>
                  {' '}
                  {CURRENCY[currency]}
                  {Number(price) / 100}
                </TotalInfo>
              )}
            </Flex>
          </Card>
        </Card>

        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={paymentOptionSelected}
          onChange={handleChange}
        >
          {paymentOption.map(
            (el: { id: string; card: { brand: string; last4: string } }) => {
              const {
                id,
                card: { last4, brand }
              } = el;
              const { src, width, height } = getCardImageProps(brand);
              return (
                <FormControlLabel
                  key={id}
                  value={id}
                  control={<Radio />}
                  label={
                    <>
                      <Image src={src} width={width} height={height} />
                      **** **** **** {last4}
                    </>
                  }
                />
              );
            }
          )}

          {paymentOption.length > 0 && (
            <FormControlLabel
              value="ADDCARD"
              control={<Radio />}
              label="ADD CARD"
            />
          )}
        </RadioGroup>
        {paymentOptionSelected === 'ADDCARD' || paymentOption.length === 0 ? (
          <PaymentScreenDemo
            isUpgrade={isUpgrade}
            isFreeTrial={isFreeTrial}
            amount={price}
            submitPurchaseRequest={submitPurchaseRequest}
          />
        ) : (
          <Button
            disabled={isLoading}
            variant="contained"
            onClick={() => submitPurchaseRequest(null)}
          >
            {isFreeTrial && !isUpgrade && 'Confirm Trial'}
            {isFreeTrial && isUpgrade && 'Upgrade Free Trial'}
            {!isFreeTrial &&
              `Pay ${CURRENCY[currency]}${price && (price / 100).toFixed(2)}`}
          </Button>
        )}
      </div>
    );
  }
  if (purchaseStatus === true) {
    return (
      <>
        <Flex align="flex-start" direction="column">
          <Flex align="flex-start" mbottom="24">
            <Flex>
              <Image src={PaymentSuccess} width="60" height="60" />
            </Flex>

            <Flex direction="column" align="start" mleft="10">
              <Flex direction="column" align="start">
                <Flex>
                  <Info>The payment was successful. </Info>
                </Flex>

                {isFreeTrial && !isUpgrade && (
                  <Typography>Your free trial has been activated!</Typography>
                )}
                {isFreeTrial && isUpgrade && (
                  <Typography>
                    Your free trial upgrade has been activated!
                  </Typography>
                )}
                {!isFreeTrial && !isUpgrade && (
                  <Typography>Your subscription has been activated!</Typography>
                )}
                {!isFreeTrial && isUpgrade && (
                  <Typography>
                    Your Upgraded subscription has been activated!
                  </Typography>
                )}

                {errorMessage !== '' && (
                  <Flex direction="column" align="baseline">
                    {/* <Image src={AddCameraFail} width="62" height="62" /> */}
                    <Typography>
                      Sorry! We were unable add the device your subscription
                      plan
                    </Typography>
                    <Typography>
                      Please contact customer service via:
                    </Typography>
                    <Flex align="center">
                      <Typography>Email: </Typography>
                      <Typography>{config.supportEmail}</Typography>
                    </Flex>

                    <Flex align="center">
                      <Typography>Phone number: </Typography>
                      <Typography>{config.phoneNumber}</Typography>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex justify="center">
          <Button variant="outlined" onClick={toggleModal}>
            Go To Subscription
          </Button>
        </Flex>
      </>
    );
  }

  if (purchaseStatus === false) {
    return (
      <>
        <Flex align="flex-start" direction="column">
          <Flex align="center" mbottom="24">
            <Image src={PaymentFailure} width="60" height="60" />
            <Flex direction="column" align="start" mleft="10">
              <Info>Payment Declined</Info>
              <Info>Oh snap! The credit card information was declined</Info>
            </Flex>
          </Flex>
        </Flex>
        <Flex justify="center">
          <Button variant="outlined" onClick={() => setPurchaseStatus(null)}>
            Try Again
          </Button>
        </Flex>
      </>
    );
  }

  return <Spinner />;
}
