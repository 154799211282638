import { Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import PaymentScreenDemo from 'views/PaymentScreen/PaymentScreen.demo';

function AddPaymentMethod() {
  const navigate = useNavigate();

  const addCardDetails = () => {
    navigate(-1);
  };
  return (
    <>
      <Typography variant="h3" margin="30px 0px 15px 10px">
        ADD CARD DETAILS
      </Typography>

      <PaymentScreenDemo
        isUpgrade={false}
        isFreeTrial={false}
        amount={-1}
        submitPurchaseRequest={() => {
          addCardDetails();
        }}
      />
    </>
  );
}

export default AddPaymentMethod;
