import { Typography, Stack } from '@mui/material';

import LaunchAppButton from 'components/LaunchAppButton';
import { ConfigContext } from 'context';
import { useContext } from 'react';

function MultiCameraAdded() {
  const config = useContext(ConfigContext);
  const MultiCameraIcon = config.multiCameraAddedIllustration;
  return (
    <>
      <Stack textAlign="center" alignItems="center">
        <MultiCameraIcon width="300" height="300" />
        <Typography variant="h2" marginTop="24px">
          All cameras have been successfully added to your plan!
        </Typography>
      </Stack>

      <Stack alignItems="strech" marginTop="39px">
        <LaunchAppButton />
      </Stack>
    </>
  );
}

export default MultiCameraAdded;
