/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */

import { Typography, Stack } from '@mui/material';
import { TStatusMessages, TSupportedFeature } from 'typings';
import CameraIcon from 'assets/subscriptionIcons/camera_icon.svg';
import Alarm from 'assets/subscriptionIcons/alarm.svg';
import CloudAdd from 'assets/subscriptionIcons/cloud-add.svg';
import Monitoring from 'assets/subscriptionIcons/eye.svg';
import Sharing from 'assets/subscriptionIcons/people_icon.svg';
import Support from 'assets/subscriptionIcons/24-support.svg';
import CloudStorage from 'assets/subscriptionIcons/cloud-storage.svg';
import VideoCameraIcon from 'assets/video-camera.png';
import SearchIcon from 'assets/subscriptionIcons/search.svg';
import ContextualAI from 'assets/subscriptionIcons/contextual-AI.svg';
import MonitoringIcon from 'assets/subscriptionIcons/monitoringIcon.svg';
import MonitoringCertificate from 'assets/subscriptionIcons/monitoring_certificate.svg';
import VideoSingleCameraIcon from 'assets/subscriptionIcons/video_1_camera.svg';
import VideoTenCameraIcon from 'assets/subscriptionIcons/video_10_camera.svg';
import Notification from 'assets/subscriptionIcons/notification-bing.svg';

type TProps__PlanFeaturesList = {
  featuresList: TSupportedFeature[];
};
const obj: TStatusMessages = {
  basic_features: CameraIcon,
  user_sharing: Sharing,
  enhanced_motion: Notification,
  '30_days_event': CloudStorage,
  enhanced_support: Support,
  self_monitoring_send_help: Alarm,
  active_monitoring: Monitoring,
  '6_months_alarm': CloudAdd,
  unlimited_camera_supported: VideoCameraIcon,
  '1_camera_supported': VideoSingleCameraIcon,
  contextual_ai: ContextualAI,
  event_search: SearchIcon,
  monitoring: MonitoringIcon,
  '10_camera_supported': VideoTenCameraIcon,
  monitoring_cert: MonitoringCertificate
};

function PlanFeaturesList(props: TProps__PlanFeaturesList) {
  const { featuresList } = props;

  console.log('featuresList -- ', featuresList);

  // const getSupportedCameraCount = (supported_features: any) => {
  //   return supported_features
  //     .filter((item: any) => item.id.includes('camera_supported'))[0]
  //     .id.split('_')[0];
  // };

  // const supportedCameraCount = getSupportedCameraCount(featuresList);

  return (
    <>
      {/* <Typography
        variant="Body1Medium"
        color="primary"
        sx={{
          display: 'block',
          textTransform: 'capitalize',
          margin: '16px 0 32px 0'
        }}
      >
        {`${supportedCameraCount} ${
          Number(supportedCameraCount) === 1 ? 'camera only' : 'cameras'
        }`}
      </Typography> */}
      <Stack alignItems="center" gap={1}>
        {featuresList.map((element: TSupportedFeature) => (
          <Stack
            key={element.id}
            direction="row"
            alignItems="center"
            gap={1}
            width="100%"
          >
            <img
              alt="feature list"
              src={obj[element.id]}
              style={{ width: '20px', height: '20px' }}
            />
            <Typography variant="Body1">{element.description}</Typography>
          </Stack>
        ))}
      </Stack>
    </>
  );
}

export default PlanFeaturesList;
