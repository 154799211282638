/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable spaced-comment */

import { Button, Typography, Stack, Radio, Chip } from '@mui/material';
import { useLocation } from 'react-router-dom';
//import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import axios from 'axios';
// import { TDeviceResponseType } from 'typings';
// import { endpoints } from 'services/api/endpoints';
import { ReactComponent as CameraIcon } from 'assets/camera-icon.svg';
import { getSubscriptionDetails } from 'services/api/subscriptionApi';

import InstaCard from 'components/InstaCard';
import InstaSpinner from 'components/InstaSpinner';

function CameraSelection() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentSubscriptionObj, setCurrentSubscriptionObj] = useState<any>({});
  const location = useLocation();
  const { subscriptionObj } = location.state || {};
  //const navigate = useNavigate();
  //const [selectedCameras, setSelectedCameras] = useState<string[]>([]);
  //const [cameras, setCameras] = useState<TDeviceResponseType[]>([]);
  const query = new URLSearchParams(location.search);
  const spaceId = query.get('space_id') || '';
  console.log('spaceID :: ', spaceId);
  const subscriptionId = query.get('subscription_id') || '';
  console.log('sunID :: ', subscriptionId);
  //const cameraCount = 1;

  // const fetchAllCameras = () => {
  //   try {
  //     console.log('spaceID :: ', spaceId);
  //     axios.get(endpoints.getAllDeviceBySpaceIdUrl(spaceId)).then((res) => {
  //       setCameras(res.data.devices);
  //     });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  // const toggleCameraSelection = (cameraId: string) => {
  //   if (selectedCameras.includes(cameraId)) {
  //     setSelectedCameras(selectedCameras.filter((id) => id !== cameraId));
  //   } else if (selectedCameras.length < cameraCount) {
  //     setSelectedCameras([...selectedCameras, cameraId]);
  //   }
  // };
  // const addCameraToSubscription = () => {
  //   const payload = {
  //     device_id: selectedCameras[0]
  //   };

  //   axios
  //     .put(endpoints.addDevice(subscriptionId), payload)
  //     .then(() => {
  //       navigate('/subscription-summary');
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  useEffect(() => {
    setIsLoading(true);
    getSubscriptionDetails()
      .then((res) => {
        console.log('subdata', res.data);
        const found = res.data.find((item) => item.id === subscriptionObj.id);

        setCurrentSubscriptionObj(found);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <InstaSpinner withWrapper />;
  }

  return (
    <Stack>
      <Typography textAlign="center" variant="h2" marginBottom="12px">
        select camera to enable
      </Typography>
      <Stack alignItems="center" marginBottom="43px">
        <Chip
          label={subscriptionObj?.sku_name}
          sx={{
            background: (theme) =>
              subscriptionObj.sku_name === 'Basic'
                ? theme.palette.background.yellow
                : theme.palette.gradient.green,
            color: (theme) => theme.palette.text.contrastText
          }}
        />
      </Stack>
      <InstaCard>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          key="000-11"
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <CameraIcon />
            <Stack direction="column">
              <Typography variant="h4">
                {currentSubscriptionObj.devices[0].name}
              </Typography>
              <Typography
                variant="Body1Medium"
                sx={{ color: (theme) => theme.palette.primary.main }}
              >
                Outdoor 1080p
              </Typography>
            </Stack>
          </Stack>
          <Radio />
        </Stack>
      </InstaCard>

      <Button
        variant="contained"
        color="secondary"
        //onClick={addCameraToSubscription}
        sx={{ alignItems: 'stretch' }}
      >
        Continue
      </Button>

      {/* <CameraContainer>
          {cameras.map((item) => (
            <CameraBlock
              key={item.id}
              isSelected={selectedCameras.includes(item.id)}
              onClick={() => toggleCameraSelection(item.id)}
            >
              <LeftInfoContainer>
                <HeadingText isSelected={selectedCameras.includes(item.id)}>
                  {item.name}
                </HeadingText>
                <Typography>{item.manufacturer.model}</Typography>
                <Typography>Not covered</Typography>
              </LeftInfoContainer>
              <RightImageContainer>
                <Image src={Camera} />
              </RightImageContainer>
            </CameraBlock>
          ))}
        </CameraContainer>
      </TopContainer>
      <BottomContainer>
        <Button
          size="large"
          variant="contained"
          color="primary"
          style={{
            width: '90%'
          }}
          onClick={addCameraToSubscription}
        >
          Continue
        </Button>
      </BottomContainer>
    </Container> */}
    </Stack>
  );
}
export default CameraSelection;
