import { Card, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';

function ProtectionBadge({ skuname }: { skuname: string }) {
  return (
    <Card
      sx={{
        borderRadius: '29px',
        color: (theme) => theme.palette.text.contrastText,
        background: (theme) => theme.palette.background.overlayBanner2,
        marginBottom: isMobile ? '5px' : '9.58px',
        padding: '10px 15px 10px 15px'
      }}
    >
      <Typography variant="h3">Protected by {skuname} service</Typography>
    </Card>
  );
}

export default ProtectionBadge;
