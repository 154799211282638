import { Avatar, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useAppSelector } from 'store/hooks';
import {
  TSubscriptionType,
  TSpaceResponseData,
  SpaceRole,
  SUBSCRIPTION_TYPE
} from 'typings';
import InstaCard from 'components/InstaCard';
import { getSubscriptionDetails } from 'services/api/subscriptionApi';
import { GetAllSpaces } from 'services/api/SpacesApi';
import { useState, useEffect } from 'react';
import InstaSpinner from 'components/InstaSpinner';

function UserAccount() {
  const userDetails = useAppSelector((state) => state.user.userDetails);
  const [spaceData, setSpaceData] = useState<TSpaceResponseData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionData, setSubscriptionList] = useState<TSubscriptionType[]>(
    []
  );

  useEffect(() => {
    Promise.all([getSubscriptionDetails(), GetAllSpaces()])
      .then((res) => {
        const [subscriptionsData, allSpacesResponse] = res;
        console.log('subscriptionsData---', subscriptionsData);
        setSubscriptionList(subscriptionsData.data);
        const ownerSpace = allSpacesResponse.data.filter((item) => {
          return item.role === SpaceRole.OWNER;
        });
        setSpaceData(ownerSpace);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  console.log('spaceData : ', spaceData);
  console.log('user : ', userDetails);
  console.log('subscriptionData : ', subscriptionData);

  if (isLoading) {
    return <InstaSpinner withWrapper />;
  }

  return (
    <>
      {!isLoading && spaceData.length > 0 && (
        <Stack>
          <Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ mb: '44px' }}
            >
              <Avatar sx={{ height: 136, width: 136 }} />
            </Stack>

            <InstaCard>
              {userDetails.name.first ? (
                <Typography
                  variant="h4"
                  sx={{ height: '29px', mb: '20px', ml: '10px' }}
                >
                  {`${userDetails?.name.first} ${userDetails?.name.last}`}
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  sx={{
                    height: '29px',
                    mb: '20px',
                    ml: '10px',
                    color: 'text.shadedText'
                  }}
                >
                  User Name
                </Typography>
              )}

              {userDetails.phone_number.code &&
              userDetails?.phone_number.value ? (
                <Typography
                  variant="h4"
                  sx={{
                    height: '29px',
                    mt: '20px',
                    ml: '10px',
                    pointerEvents: 'none'
                  }}
                >{`${userDetails?.phone_number.code} ${userDetails?.phone_number.value}`}</Typography>
              ) : (
                <Typography
                  variant="h6"
                  color="text.shadedText"
                  sx={{
                    height: '29px',
                    mt: '20px',
                    ml: '10px',
                    pointerEvents: 'none'
                  }}
                >
                  Phone Number
                </Typography>
              )}
            </InstaCard>

            <InstaCard>
              {[
                <Typography
                  variant="h4"
                  key="0001"
                  sx={{ pointerEvents: 'none' }}
                >
                  {userDetails?.email || ''}{' '}
                </Typography>
              ]}
            </InstaCard>
          </Stack>

          <Stack sx={{ mt: '10px' }}>
            <Typography variant="h3" sx={{ mb: '16px', ml: '10px' }}>
              ACCOUNT HISTORY
            </Typography>
            {subscriptionData[0]?.subscription_type ===
              SUBSCRIPTION_TYPE.PREVIEW && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  height: '60px',
                  mb: '20px',
                  ml: '10px',
                  mr: '10px',
                  borderRadius: '20px',
                  background: '#EDEDED',
                  position: 'relative',
                  padding: '20px 10px',
                  boxSizing: 'border-box',
                  boxShadow: 'none',
                  marginBottom: '20px',
                  overflow: 'auto',
                  '&::before': {
                    backgroundImage:
                      'linear-gradient(45deg, black, transparent)',
                    borderRadius: '20px',
                    padding: '1px',
                    width: '100%',
                    height: '100%',
                    top: '-1px',
                    left: '-1px',
                    zIndex: '-1',
                    position: 'absolute',
                    content: '""'
                  }
                }}
              >
                <Typography variant="h4">Subscription</Typography>
                <Typography variant="Body1">
                  {subscriptionData[0]?.sku_name || 'NA'}
                </Typography>
              </Stack>
            )}
            {subscriptionData[0]?.subscription_type !==
              SUBSCRIPTION_TYPE.PREVIEW && (
              <InstaCard>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ height: '29px', mb: '20px', ml: '10px', mr: '10px' }}
                >
                  <Typography variant="h4">Subscription</Typography>
                  <Typography variant="Body1">
                    {subscriptionData[0]?.sku_name || 'NA'}
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ height: '29px', mt: '20px', ml: '10px', mr: '10px' }}
                >
                  <Typography variant="h4">Payment Due</Typography>
                  <Typography variant="Body1">
                    {subscriptionData.length > 0
                      ? dayjs(subscriptionData[0]?.next_billing_date).format(
                          'MMM D, YYYY'
                        )
                      : 'NA'}
                  </Typography>
                </Stack>
              </InstaCard>
            )}
          </Stack>
        </Stack>
      )}
      {!isLoading && spaceData.length === 0 && (
        <InstaCard>
          {[
            <Stack alignItems="center" key="1010">
              <Typography variant="h3">No Space Created</Typography>
            </Stack>
          ]}
        </InstaCard>
      )}
    </>
  );
}

export default UserAccount;
