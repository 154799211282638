export enum TOKEN {
  AUTH_TOKEN = 'auth-token',
  REFRESH_TOKEN = 'refresh-token'
}

export const FREE_TRIAL_DURATION = 7;

export const PLAN_DETAILS = {
  MONTHLY: {
    SOLO: {
      LABEL: 'Monthly',
      CYCLE: 1
    },
    ENHANCED: {
      LABEL: 'Enhanced',
      CYCLE: 1
    },
    PREMIUM: {
      LABEL: 'Premium',
      CYCLE: 1
    }
  },
  YEARLY: {
    SOLO: {
      LABEL: 'Monthly',
      CYCLE: 12
    },
    ENHANCED: {
      LABEL: 'Enhanced',
      CYCLE: 12
    },
    PREMIUM: {
      LABEL: 'Premium',
      CYCLE: 12
    }
  }
};
