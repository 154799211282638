/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const redirectionURL = '/addCameraInfo';

function AuthorizeDeviceFromApp() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const spaceId = query.get('space_id') || '';
  const deviceId = query.get('device_id') || '';

  useEffect(() => {
    navigate(`${redirectionURL}?space_id=${spaceId}&device_id=${deviceId}`);
  }, []);

  return null;
}
export default AuthorizeDeviceFromApp;
