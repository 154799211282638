/* eslint-disable react/no-unused-prop-types */
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import axios from 'axios';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { SubscriptionPlanContent } from 'components/SubscriptionPlanContent/SubscriptionPlanContent';
import PaymentOptions from 'views/SubscriptionInfo/componenets/PaymentOptions';
import { useLocation, useNavigate } from 'react-router-dom';
import { IsMobile } from 'hooks/isMobile';
import { useAppSelector } from 'store/hooks';
import { endpoints } from 'services/api/endpoints';
import {
  TPurchasePayload,
  TPlanList,
  TProps_BuySubscription,
  TSubscriptionType
} from 'typings';

function BuySubscriptionOld(props: TProps_BuySubscription) {
  const location = useLocation();
  const {
    selectedSubscription,
    togglePrimaryModal,
    isUpgrade,
    isFreeTrial,
    deviceId
  } = Object.keys(props).length > 0 ? props : location.state;
  const { items } = useAppSelector((state) => state.space.spaceData);
  const [planSelected, setPlanSelected] = useState<TPlanList>();
  const [purchaseId, setPurchaseId] = useState<string>('');
  const [open, setOpen] = useState(false);
  const isMobile = IsMobile();
  const spaceId = items[0]['id'];
  const subscriptionData = useAppSelector<TSubscriptionType[]>(
    (state) => state.subscription.subscriptionData
  );
  const navigate = useNavigate();
  const openModal = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const toggleSecondaryModal = () => {
    if (togglePrimaryModal) {
      togglePrimaryModal();
    } else {
      navigate('/MySubscription');
    }
    onClose();
  };
  const createPurchaseId = (plan: TPlanList) => {
    const payload: TPurchasePayload = {
      country: 'US',
      sku_id: plan.id,
      trial: isFreeTrial,
      space_id: spaceId
    };
    if (isUpgrade && subscriptionData.length > 0) {
      payload['action'] = 'Upgrade';
      payload['previous_purchase_id'] = subscriptionData[0].id;
    }
    axios
      .post(endpoints.createPurchaseIdUrl, payload)
      .then((res) => {
        setPurchaseId(res.data.purchase_id);
        if (isMobile) {
          navigate('/payment-selections', {
            state: {
              isUpgrade,
              isFreeTrial,
              deviceId,
              planSelected: plan,
              purchaseId: res.data.purchase_id
            }
          });
        } else {
          openModal();
        }
      })
      .catch((err) => console.log('purchases err', err));
  };
  console.log('planSelected', planSelected);
  console.log('selectedSubscription', selectedSubscription);

  const onPlanSelection = (el: TPlanList) => {
    console.log('onPlanSelection', el);
    setPlanSelected(el);
    createPurchaseId(el);
  };

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            maxWidth: '100%',
            minWidth: '75%',
            padding: '20px'
          }
        }}
        onClose={onClose}
        open={selectedSubscription === undefined && open && !isMobile}
      >
        <Button
          sx={{
            position: 'absolute',
            right: '20px',
            top: '20px',
            padding: '0px',
            width: '20px',
            minWidth: '20px',
            height: '20px',
            border: 'none',
            outline: 'none'
          }}
          onClick={onClose}
        >
          <CloseOutlinedIcon />
        </Button>
        <PaymentOptions
          isUpgrade={isUpgrade}
          deviceId={deviceId}
          planSelected={planSelected}
          purchaseId={purchaseId}
          toggleModal={toggleSecondaryModal}
          isFreeTrial={isFreeTrial}
        />
      </Dialog>
      {selectedSubscription !== undefined && open && !isMobile ? (
        <PaymentOptions
          isUpgrade={isUpgrade}
          deviceId={deviceId}
          planSelected={planSelected}
          purchaseId={purchaseId}
          toggleModal={toggleSecondaryModal}
          isFreeTrial={isFreeTrial}
        />
      ) : (
        <SubscriptionPlanContent
          onPlanSelection={onPlanSelection}
          isFreeTrial={isFreeTrial}
          isUpgrade={isUpgrade}
        />
      )}
    </>
  );
}
export default BuySubscriptionOld;
