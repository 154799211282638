import { Stack, styled as styledMUI } from '@mui/material';
import { ReactComponent as ContrastLogo } from 'assets/logo-contrast.svg';
import { TProps_InstaSpinner } from 'typings';

const Spinner = styledMUI('div')(({ theme }) => ({
  '@keyframes interlaced': {
    '100%': {
      transform: 'rotate(1turn)'
    }
  },
  width: '100px',
  aspectRatio: 1,
  display: 'grid',
  border: '4px solid #0000',
  borderRadius: '50%',
  borderRightColor: theme.palette.background.secondary,
  animation: 'interlaced 1s infinite linear',

  '&::before, &::after': {
    content: '""',
    gridArea: '1/1',
    margin: '8px',
    border: 'inherit',
    borderRadius: '50%',
    animation: 'interlaced 2s infinite'
  },
  '&::after': {
    margin: '2px',
    animationDuration: '3s'
  }
}));

function InstaSpinner({ withWrapper = false }: TProps_InstaSpinner) {
  return (
    <Stack
      display="-webkit-box !important"
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        '& svg': { marginLeft: -8.25 },
        height: withWrapper ? '60vh' : '100%',
        justifyContent: withWrapper ? 'center' : 'unset'
      }}
    >
      <Spinner />
      <ContrastLogo />
    </Stack>
  );
}
export default InstaSpinner;
