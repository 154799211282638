// /* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react';
import { Typography, Stack } from '@mui/material';
import IOSSwitch from 'components/IOSSwitch';
import SavePercentage from 'assets/save_percentage.svg';
import styled from '@emotion/styled';

const SavePercentageContainer = styled.div`
  text-align: center;
`;
type TProps__PlanCycleSwitcher = {
  onChange: (arg0: boolean) => void;
};

function PlanCycleSwitcher(props: TProps__PlanCycleSwitcher) {
  const [checked, toggleChecked] = useState(true);
  const { onChange } = props;

  const handleChange = () => {
    toggleChecked(!checked);
    onChange(!checked);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Typography
          variant="h6"
          color={!checked ? 'text.primary' : 'text.disabled'}
        >
          Monthly
        </Typography>
        <IOSSwitch
          sx={{ m: 1, color: '#5FA4FB' }}
          checked={checked}
          onChange={handleChange}
        />
        <Typography
          variant="h6"
          color={checked ? 'text.primary' : 'text.disabled'}
        >
          Yearly
        </Typography>
      </Stack>
      {!checked && (
        <SavePercentageContainer>
          <img src={SavePercentage} alt="Save Percentage" />
        </SavePercentageContainer>
      )}
    </>
  );
}

export default PlanCycleSwitcher;
