/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from 'configs/firebaseConfig';
import { setRequestHeaders } from 'utils/commonFunctions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUser } from 'store/reducers/userReducers';
import { clearReduxStates } from 'store/store';
import axios from 'axios';
import { TStatusMessages } from 'typings';
import { endpoints } from 'services/api/endpoints';
import InstaSpinner from 'components/InstaSpinner';
import { Stack, Typography } from '@mui/material';

const redirectionURL = '/addCameraInfo';

const statusMessage: TStatusMessages = {
  authorizing: 'Authorizing Request...',
  token_expired: 'Link is expired or invalid. Please generate link again.'
};
function AuthorizeDevice() {
  const [status, setStatus] = useState<string>('authorizing');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const [customTokenVerified, setCustomTokenVerified] = useState(false);
  const query = new URLSearchParams(location.search);
  const token = query.get('token') || '';
  const spaceId = query.get('space_id') || '';
  const deviceId = query.get('device_id') || '';
  const signInWithCustomTokenFunc = async (customToken: string) => {
    try {
      const res = await signInWithCustomToken(auth, customToken);
      const newToken = await res.user.getIdToken();
      setRequestHeaders(newToken);
      dispatch(fetchUser());
      setCustomTokenVerified(true);
    } catch (err: any) {
      setStatus('token_expired');
      console.log(err);
    }
  };

  const verifyToken = (one_time_token: string) => {
    axios
      .post(endpoints.verifyToken, {
        one_time_token
      })
      .then((res) => {
        if (res.data.status === 'Valid') {
          signInWithCustomTokenFunc(res.data.custom_token);
        } else {
          setStatus('token_expired');
        }
      })
      .catch((err) => {
        setStatus('token_expired');
        console.log('fetchAccessToken', err);
      });
  };

  useEffect(() => {
    if (token === '') {
      setStatus('token_expired');
      return;
    }
    clearReduxStates();
    verifyToken(token);
  }, [token]);

  useEffect(() => {
    if (isAuthenticated && customTokenVerified) {
      navigate(`${redirectionURL}?space_id=${spaceId}&device_id=${deviceId}`);
    }
  }, [isAuthenticated, customTokenVerified]);

  return (
    <Stack justifyContent="center" alignItems="center" height="100vh">
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={2}
        margin={2.5}
      >
        {status === 'authorizing' && <InstaSpinner withWrapper />}
        <Typography variant="h2">{statusMessage[status]}</Typography>
      </Stack>
    </Stack>
  );
}
export default AuthorizeDevice;
