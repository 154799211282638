/* eslint-disable react/jsx-key */

import { Stack, Typography } from '@mui/material';
import Device from 'pages/dashboard/device';
import InstaCard from 'components/InstaCard';
import { TDeviceResponseType } from 'typings/deviceTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DeviceList(props: any) {
  const { devicesList } = props;
  return (
    <>
      {devicesList.length > 0 &&
        devicesList.map((device: TDeviceResponseType) => {
          const { snapshot_url } = device?.device_state || '';

          const maxHeight = devicesList.length === 1 ? '80vh' : '60vh';
          return (
            <Device
              deviceLength={devicesList.length}
              maxHeight={maxHeight}
              snapshot_url={snapshot_url}
              device={device}
              key={device.id}
            />
          );
        })}
      {devicesList.length === 0 && (
        <InstaCard>
          {[
            <Stack alignItems="center">
              <Typography variant="h3">No Devices Added</Typography>
            </Stack>
          ]}
        </InstaCard>
      )}
    </>
  );
}

export default DeviceList;
