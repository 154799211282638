import { useStripe } from '@stripe/react-stripe-js';
import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Stripe3DS(props: any) {
  const stripe = useStripe();
  const {
    client_secret,
    paymentOptionSelected,
    polling,
    setShowStripePaymentFailedPopUp,
    setStripeErrorResponse,
    setIsApiLoading,
    setTriggerStripePayment
  } = props;
  const pay = async () => {
    setIsApiLoading(true);
    try {
      const confirmPayment = await stripe?.confirmCardPayment(client_secret, {
        payment_method: paymentOptionSelected
      });

      if (confirmPayment?.error) {
        const { code, message } = confirmPayment.error;
        setShowStripePaymentFailedPopUp(true);
        setStripeErrorResponse({ code, message });
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paymentIntent: any = confirmPayment?.paymentIntent;

      if (paymentIntent.status === 'succeeded') {
        // Call the polling function here
        polling();
      } else {
        console.log('Awaiting confirmation');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsApiLoading(false);
      setTriggerStripePayment(false);
    }
  };
  useEffect(() => {
    if (stripe) {
      pay();
    }
  }, [stripe]);
  return null;
}

export default Stripe3DS;
