import React, { Component, ErrorInfo } from 'react';
import { Flex } from 'styles';
import { TProps, TState_ErrorBoundry } from 'typings';
import { Typography } from '@mui/material';

class ErrorBoundary extends Component<TProps, TState_ErrorBoundry> {
  constructor(props: TProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error: ', error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return (
        <Flex justify="center" align="center" height="100%" direction="column">
          <Typography style={{ textAlign: 'center' }}>
            Something went wrong.
          </Typography>
          <Typography>Please refresh</Typography>
        </Flex>
      );
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundary;
