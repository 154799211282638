import { Button } from '@mui/material';
import { ConfigContext } from 'context';
import { useContext } from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

function LaunchAppButton() {
  const navigate = useNavigate();
  const config = useContext(ConfigContext);
  return (
    <Button
      variant="contained"
      color="inherit"
      fullWidth
      sx={{
        marginBottom: '16px'
      }}
      onClick={() => {
        if (isMobile && (isAndroid || isIOS)) {
          navigate('/launchMobileApp');
        } else {
          navigate('/dashboard');
        }
      }}
    >
      Open {config.label}
    </Button>
  );
}
export default LaunchAppButton;
