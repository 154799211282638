// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import envConfig from './envConfig';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: envConfig('firebaseApiKey'),
  authDomain: envConfig('firebaseAuthDomain'),
  projectId: envConfig('firebaseProjectId'),
  storageBucket: envConfig('firebaseStorageBucket'),
  messagingSenderId: envConfig('firebaseSenderId'),
  appId: envConfig('firebaseAppId'),
  measurementId: envConfig('firebaseMeasureId')
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const appleProvider = new OAuthProvider('apple.com');
