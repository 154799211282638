import { object, bool, string, ObjectSchema } from 'yup';
import { TDeleteAccountRequest } from 'typings';

const AccountDeletionRequestFormValidationSchema: ObjectSchema<TDeleteAccountRequest> =
  object({
    email: string().defined().email().required(),
    confirmChecked: bool()
      .defined()
      .oneOf([true], 'Field must be checked')
      .required()
  });

export default AccountDeletionRequestFormValidationSchema;
