import { TStatusMessages } from 'typings';

export const CURRENCY: TStatusMessages = {
  USD: '$',
  EUR: '€',
  GBP: '£'
};

export const PRODUCT_NAME = 'InstaVision';

export const CUSTOMER_SERVICE = {
  email: 'support@instavision.ai',
  phone: '+1 (877) 916-3025'
};

export const SUBSCRIBE_STATUS = {
  Active: 'Active',
  Cancelled: 'Cancelled'
};
