/* eslint-disable react/function-component-definition */
import { useEffect, useState } from 'react';
import { Button, Dialog, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import axios from 'axios';
import styled from 'styled-components';
import AddOrRemoveCamera from 'components/AddOrRemoveCamera';
import Spinner from 'components/Spinner';
import BuySubscription from 'views/BuySubscription/BuySubscription';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TDeviceResponseType, TSubscriptionType, TPlanList } from 'typings';
import { getSubscriptionDetails } from 'services/api/subscriptionApi';
import { endpoints } from 'services/api/endpoints';
import { getPlanDetails } from 'services/api/plansApi';
import { IsMobile } from 'hooks/isMobile';
import {
  fetchAllSubscription,
  updateSubscriptionData
} from 'store/reducers/subscriptionReducer';
import { SubscriptionInfoCard } from './componenets/SubscriptionInfoCard';
import { CustomerServiceContent } from './componenets/CustomerServiceContent';
import NoSubscription from './componenets/NoSubscription';

const Container = styled.div`
  & > div {
    margin-bottom: 20px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${IsMobile() ? '0px' : '20px'};
  background-color: ${IsMobile() ? '#fff' : '#ffff'};
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: ${IsMobile()
    ? 'none'
    : `
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    `};
`;

const CardHeading = styled.div`
  color: #494141;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;

const HeadingIcon = styled.div`
  min-width: 16px;
  height: 32px;
  border-radius: 4px;
  background: #d7eea1;
  margin-right: 16px;
`;

const FlexContainer = styled.div<{ bottom?: string }>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.bottom || 15}px;
`;

export const SubscriptionDetails = () => {
  const spaceData = useAppSelector((state) => state.space.spaceData);
  const isSpaceDataLoading = useAppSelector((state) => state.space.isLoading);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [showSnackBar, toggleSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [selectedSubscription, setSelectedSubscription] =
    useState<TSubscriptionType | null>(null);
  const isEligibleForTrial = useAppSelector(
    (state) => state.user.isEligibleForTrial
  );
  const [selectedSpaceDeviceData, setSelectedSpacDeviceeData] = useState<
    TDeviceResponseType[]
  >([]);

  const [plans, setPlans] = useState<TPlanList[]>([]);
  const subscriptionData = useAppSelector<TSubscriptionType[]>(
    (state) => state.subscription.subscriptionData
  );
  const dispatch = useAppDispatch();

  const openModal = (value: string) => {
    setOpen(true);
    setSelectedValue(value);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedValue('');
  };

  const toggleModal = () => {
    setOpen((state) => !state);
  };

  const hideSnackBar = () => {
    toggleSnackBar(false);
  };

  const fetchSubscriptionData = () => {
    setIsLoading(true);
    setIsPolling(true);
  };

  useEffect(() => {
    if (isPolling) {
      const found = subscriptionData.find(
        (item) => item.purchase_id === selectedSubscription?.purchase_id
      );
      if (found) {
        dispatch(fetchAllSubscription());
      } else {
        setIsPolling(false);
        setIsLoading(false);
        setSnackBarMsg(
          'Your Subscription is successfully cancelled during free trial period. Your card will not be charged.'
        );
        toggleSnackBar(true);
      }
    }
  }, [isPolling, subscriptionData]);

  useEffect(() => {
    if (spaceData.length > 0 && plans.length === 0 && isLoading === false) {
      setIsLoading(true);
      const spaceId = spaceData[0];
      Promise.all([
        axios.get(endpoints.getAllDeviceBySpaceIdUrl(spaceId?.id || '')),
        getPlanDetails(),
        getSubscriptionDetails()
      ])
        .then((res) => {
          const [spaceDevices, allPlans, subscriptionDetails] = res;
          setSelectedSpacDeviceeData(spaceDevices.data.items);
          setPlans(allPlans.data);
          dispatch(updateSubscriptionData(subscriptionDetails.data));
        })
        .catch((err) => {
          console.log('err', err);
        })
        .finally(() => {
          setIsLoading(false);
          setIsDataFetched(true);
        });
    } else {
      setIsDataFetched(true);
    }
  }, [spaceData, isLoading]);

  const snackBarAction = (
    <IconButton size="small" color="inherit" onClick={hideSnackBar}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  if (isLoading || isSpaceDataLoading) {
    return <Spinner />;
  }

  if (isDataFetched && spaceData?.length === 0) {
    return (
      <Card style={{ marginTop: '24px' }}>
        <FlexContainer bottom="12">
          <HeadingIcon />
          <CardHeading>No Space Created</CardHeading>
        </FlexContainer>
      </Card>
    );
  }
  return isDataFetched &&
    spaceData?.length &&
    subscriptionData?.length === 0 ? (
    <>
      <NoSubscription
        isEligibleForTrial={isEligibleForTrial}
        deviceId={selectedSpaceDeviceData[0]?.id}
      />
      {showSnackBar && snackBarMsg && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showSnackBar}
          autoHideDuration={5000}
          onClose={hideSnackBar}
          message={snackBarMsg}
          action={snackBarAction}
        />
      )}
    </>
  ) : (
    <Container>
      <Dialog
        PaperProps={{
          style: {
            maxWidth: '100%',
            minWidth: '50%',
            padding: '20px'
          }
        }}
        onClose={onClose}
        open={open}
      >
        <Button
          sx={{
            position: 'absolute',
            right: '20px',
            top: '20px',
            padding: '0px',
            width: '20px',
            minWidth: '20px',
            height: '20px',
            border: 'none',
            outline: 'none'
          }}
          onClick={onClose}
        >
          <CloseOutlinedIcon />
        </Button>
        {selectedValue === 'ADD_OR_REMOVE_CAMERA' && (
          <AddOrRemoveCamera
            selectedSubscription={selectedSubscription}
            openModal={openModal}
          />
        )}
        {selectedValue === 'CANCEL' && <CustomerServiceContent />}
        {selectedValue === 'MODIFY' && (
          <BuySubscription
            isUpgrade
            isFreeTrial={
              selectedSubscription
                ? selectedSubscription.trial
                : isEligibleForTrial
            }
            togglePrimaryModal={toggleModal}
            selectedSubscription={selectedSubscription}
          />
        )}
      </Dialog>
      {subscriptionData?.length > 0 &&
        plans?.length > 0 &&
        subscriptionData?.map((item) => (
          <SubscriptionInfoCard
            plans={plans}
            key={item.id}
            openModal={openModal}
            subscriptionInfo={item}
            setSelectedSubscription={setSelectedSubscription}
            onCancelFreeTrial={fetchSubscriptionData}
          />
        ))}
      {showSnackBar && snackBarMsg && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showSnackBar}
          autoHideDuration={5000}
          onClose={hideSnackBar}
          message={snackBarMsg}
          action={snackBarAction}
        />
      )}
    </Container>
  );
};
