/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, Divider } from '@mui/material';

function InstaCard(props: any) {
  const { children } = props;
  console.log('props -- ', props);
  return (
    <Card
      sx={{
        borderRadius: '20px',
        background: '#EDEDED',
        position: 'relative',
        padding: '20px 10px',
        boxSizing: 'border-box',
        boxShadow: 'none',
        marginBottom: '20px',
        overflow: 'auto',
        '&::before': {
          backgroundImage: 'linear-gradient(45deg, black, transparent)',
          borderRadius: '20px',
          padding: '1px',
          width: '100%',
          height: '100%',
          top: '-1px',
          left: '-1px',
          zIndex: '-1',
          position: 'absolute',
          content: '""'
        }
      }}
    >
      {children.map((child: any, index: any) => (
        <>
          {child}
          {index !== children.length - 1 && <Divider />}
        </>
      ))}
    </Card>
  );
}

export default InstaCard;
