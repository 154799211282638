/* eslint-disable @typescript-eslint/no-explicit-any */

import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Box,
  Button,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

function NoSubscriptionBanner({ onClickCancel }: { onClickCancel: any }) {
  const navigate = useNavigate();

  const handleClearClick = () => {
    onClickCancel();
  };
  return (
    <Card
      sx={{
        borderRadius: '29px',
        color: (theme) => theme.palette.text.contrastText,
        background: (theme) => theme.palette.background.overlayBanner
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-end"
        height="18px"
        marginBottom={isMobile ? '0px' : '6.5px'}
        marginTop={isMobile ? '4px' : '10px'}
        marginRight="20px"
      >
        <IconButton
          size="small"
          sx={{
            color: '#ffffff',
            borderStyle: '1.5px',
            '& .MuiSvgIcon-root': { width: isMobile ? '0.5em' : '1em' }
          }}
          onClick={handleClearClick}
        >
          <ClearIcon />
        </IconButton>
      </Stack>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginBottom="5px"
        paddingLeft={isMobile ? '5px' : '22px'}
        paddingRight={isMobile ? '5px' : '22px'}
      >
        <Typography variant={`${isMobile ? 'Body1' : 'h4'}`} textAlign="center">
          Not protected by this subscription
        </Typography>
        <Box textAlign="center">
          <Typography variant={`${isMobile ? 'Body2' : 'Body1SemiBold'}`}>
            Get more out of your camera. Review, save, and share videos from
            your camera.
          </Typography>
        </Box>
      </Stack>

      <Stack direction="row" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          sx={{
            padding: isMobile ? '3px 20px' : '10px 20px',
            fontSize: (theme) => theme.typography.Body1.fontSize,
            fontWeight: (theme) => theme.typography.Body1.fontWeight,
            lineHeight: (theme) => theme.typography.Body1.lineHeight,
            marginBottom: isMobile ? '3px' : '10px'
          }}
          onClick={() => {
            navigate('/subscriptions');
          }}
        >
          Add Camera Plan
        </Button>
      </Stack>
    </Card>
  );
}

export default NoSubscriptionBanner;
