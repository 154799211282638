import {
  checkFreeTrialEligibility,
  getSubscriptionDetails
} from 'services/api/subscriptionApi';
import {
  SpaceRole,
  TDeviceResponseType,
  TSpaceResponseData,
  TSubscriptionType
} from 'typings';
import DeviceList from 'pages/dashboard/deviceList';
import { useState, useEffect } from 'react';
import InstaSpinner from 'components/InstaSpinner';
import ExploreBanner from 'pages/dashboard/exploreNowBanner';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import axios from 'axios';
import { endpoints } from 'services/api/endpoints';
import { updateFreeTrialEligibility } from 'store/reducers/userReducers';
import { GetAllSpaces } from 'services/api/SpacesApi';
import { fetchAllSpaces } from 'store/reducers/spaceReducers';
import { Stack, Typography } from '@mui/material';
import InstaCard from 'components/InstaCard';

function Dashboard() {
  const dispatch = useAppDispatch();
  const [subscriptionList, setSubscriptionList] = useState<TSubscriptionType[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [devicesList, setDevicesList] = useState<TDeviceResponseType[]>([]);
  const [spaceData, setSpaceData] = useState<TSpaceResponseData[]>([]);
  const [showDeviceList, setShowDeviceList] = useState(false);
  const isEligibleForTrial = useAppSelector(
    (state) => state.user.isEligibleForTrial
  );
  const findLinkedSubscriptionByDeviceId = (deviceId: string) => {
    return subscriptionList.find((subscriptionDeviceList) => {
      return subscriptionDeviceList.devices.find(
        (device) => device.id === deviceId
      );
    });
  };
  useEffect(() => {
    Promise.all([
      getSubscriptionDetails(),
      checkFreeTrialEligibility(),
      GetAllSpaces()
    ])
      .then((res) => {
        const [subscriptionsData, userFreeTrial, allSpacesResponse] = res;
        console.log('subscriptionsData--234', subscriptionsData);
        dispatch(updateFreeTrialEligibility(userFreeTrial.data.trial));
        setSubscriptionList(subscriptionsData.data);
        const ownerSpace = allSpacesResponse.data.filter((item) => {
          return item.role === SpaceRole.OWNER;
        });
        setSpaceData(ownerSpace);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    dispatch(fetchAllSpaces());
  }, []);

  useEffect(() => {
    if (spaceData.length) {
      const spaceId = spaceData[0];
      axios
        .get(endpoints.getAllDeviceBySpaceIdUrl(spaceId.id || ''))
        .then((devicesData) => {
          const devices = devicesData.data.items.map(
            (deviceItem: TDeviceResponseType) => {
              const copyOfDeviceItem = { ...deviceItem };
              const found = findLinkedSubscriptionByDeviceId(deviceItem.id);
              copyOfDeviceItem.existingSubscription = found;
              return copyOfDeviceItem;
            }
          );

          setDevicesList(devices);
          setShowDeviceList(true);
        });
    }
  }, [spaceData]);

  if (isLoading) {
    return <InstaSpinner withWrapper />;
  }

  return (
    <>
      {!isLoading && subscriptionList.length === 0 && !isEligibleForTrial && (
        <ExploreBanner />
      )}
      {showDeviceList && <DeviceList devicesList={devicesList} />}
      {!isLoading && spaceData.length === 0 && (
        <InstaCard>
          {[
            <Stack alignItems="center" key="1010">
              <Typography variant="h3">No Space Created</Typography>
            </Stack>
          ]}
        </InstaCard>
      )}
    </>
  );
}

export default Dashboard;
