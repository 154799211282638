export type TPurchaseHistoryResponse = {
  id: string;
  paid_amount: number;
  pay_type: number;
  pay_date: number;
  product_type: number;
  subscription: TSubscription;
  price: TPriceData;
  sku: TSku;
};
export type TPriceData = {
  currency: string;
  price: number;
};

export type TSku = {
  data_retention_days: number;
  id: number;
  name: string;
  subscription_cycle: number;
};

export type TSubscription = {
  next_billing_date: number;
  end_date: number;
  start_date: number;
  status: SUBSCRIPTION_STATUS;
};

export enum SUBSCRIPTION_STATUS {
  INITIAL = 'Initial',
  PAYFAILED = 'PayFailed',
  SUBSCRIBED = 'Subscribed',
  CANCELLED = 'Cancelled',
  EXPIRED = 'Expired',
  RENEWFAILED = 'RenewFailed'
}

export type TPurchaseHistoryInitialState = {
  purchaseHistory: TPurchaseHistoryResponse[];
  selectedPurchaseId: string;
  transactionList: TTransactionList[];
  isLoading: boolean;
};
export enum TRANSACTION_STATUS {
  SUCCESS = 'success',
  FAILED = 'failed',
  REFUND = 'refund'
}

export type TTransactionList = {
  currency: string;
  id: string;
  paid_amount: number;
  refund_info: TRefundInfo;
  refund_transaction_id: string;
  status: TRANSACTION_STATUS;
  transaction_id: string;
  transaction_time: number;
};
export type TRefundInfo = {
  reason: string;
};

export enum SUBSCRIPTION_TYPE {
  PREVIEW = 'Preview',
  TRIAL = 'Trial',
  PAID = 'Paid'
}
