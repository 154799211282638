/* eslint-disable @typescript-eslint/no-explicit-any */
import { CURRENCY } from 'configs/constants';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import styled from 'styled-components';
import { Flex, Image } from 'styles';
import Switch from '@mui/material/Switch';
import { IsMobile } from 'hooks/isMobile';
import CameraIcon from 'assets/camera_icon.png';
import Alarm from 'assets/alarm.png';
import Notification from 'assets/notification-bing.png';
import CloudAdd from 'assets/cloud-add.png';
import Monitoring from 'assets/eye.png';
import Sharing from 'assets/people.png';
import Support from 'assets/support.png';
import CloudStorage from 'assets/cloud-storage.png';
import DiscountArrow from 'assets/discount-arrow.png';
import VideoCameraIcon from 'assets/video-camera.png';
import { checkFreeTrialEligibility } from 'services/api/subscriptionApi';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { endpoints } from 'services/api/endpoints';
import { getPlanDetails } from 'services/api/plansApi';
import Spinner from 'components/Spinner';
import { useNavigate } from 'react-router-dom';
import {
  TStatusMessages,
  TPlanList,
  TProps_SubscriptionPlanContent,
  TPurchasePayload,
  TSupportedFeature,
  TSubscriptionType
} from 'typings';
import { updateFreeTrialEligibility } from 'store/reducers/userReducers';
import { Typography } from '@mui/material';
import { FREE_TRIAL_DURATION } from 'utils/constants';

const PlanCard = styled.div<{ isPopular?: boolean }>`
  min-width: 236px;
  border-radius: 24px;
  border: 1.5px solid ${(props) => (props.isPopular ? '#5FA4FB' : '#e5ebec')};
  background: ${(props) => (props.isPopular ? '#CDE3FE' : '#e5ebec')};
  margin-right: 10px;
  padding: 16px;
  align-self: stretch;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const PlanHeading = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 4px;
`;

const FinalPlanCost = styled.div`
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.32px;
`;

const OriginalCost = styled.div`
  color: #484c4f;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: line-through;
`;

const DiscountDiff = styled.div`
  color: #fe832d;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-left: 5px;
`;

const PlanDetailPoint = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 10px;
`;

const PlanType = styled.div<{ active?: boolean }>`
  color: #494141;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  opacity: ${(props) => (props.active ? 0.3 : 1)};
`;

const DiscountInfo = styled.div`
  color: #fe832d;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  position: relative;
  margin-top: 5px;
`;

const CameraCount = styled.div`
  color: #388efa;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 12px;
  text-transform: capitalize;
`;

const FlexContainer = styled.div``;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${IsMobile() ? '0px' : '20px'};
  background-color: ${IsMobile() ? '#fff' : '#ffff'};
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: ${IsMobile()
    ? 'none'
    : `
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    `};
`;

const HeadingIcon = styled.div`
  min-width: 16px;
  height: 32px;
  border-radius: 4px;
  background: #d7eea1;
  margin-right: 16px;
`;

const FlexContent = styled.div<{ bottom?: string }>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.bottom || 15}px;
`;

const obj: TStatusMessages = {
  basic_features: CameraIcon,
  user_sharing: Sharing,
  enhanced_motion: Notification,
  '30_days_event': CloudStorage,
  enhanced_support: Support,
  self_monitoring_send_help: Alarm,
  active_monitoring: Monitoring,
  '6_months_alarm': CloudAdd,
  unlimited_camera_supported: VideoCameraIcon,
  '1_camera_supported': VideoCameraIcon
};

export function SubscriptionPlanContent(props: TProps_SubscriptionPlanContent) {
  const { onPlanSelection, isFreeTrial, isUpgrade } = props;
  console.log('isFreeTrial', isFreeTrial);
  const [allPlans, setAllPlans] = useState<TPlanList[]>([]);
  const [checked, setChecked] = useState(true);
  const subscriptionData = useAppSelector<TSubscriptionType[]>(
    (state) => state.subscription.subscriptionData
  );
  const spaceData = useAppSelector((state) => state.space.spaceData);
  const [selectedSpaceDeviceData, setSelectedSpaceDeviceData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [canPurchaseFreeTrial, toggleCanPurchaseFreeTrial] = useState(false);
  const dispatch = useAppDispatch();
  // const isMobile = IsMobile();
  const navigate = useNavigate();

  useEffect(() => {
    if (spaceData[0]?.id) {
      setIsLoading(true);
      Promise.all([
        axios.get(endpoints.getAllDeviceBySpaceIdUrl(spaceData[0].id || '')),
        getPlanDetails(),
        checkFreeTrialEligibility()
      ])
        .then((res) => {
          const [allDevices, allPlan, freeTrialEligibility] = res;
          setAllPlans(allPlan.data);
          setSelectedSpaceDeviceData(allDevices.data.items);

          toggleCanPurchaseFreeTrial(freeTrialEligibility.data.trial);

          dispatch(updateFreeTrialEligibility(freeTrialEligibility.data.trial));
        })
        .catch((err) => {
          console.log('err', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [spaceData]);

  const handleChange = () => {
    setChecked(!checked);
  };

  const findCurrentPlanLevel = () => {
    let planLevel = 0;
    // checking for level wrt current subscription
    allPlans?.forEach((el: TPlanList) => {
      if (el?.id === subscriptionData[0]?.sku_id) {
        planLevel = Number(el.level);
      }
    });
    return planLevel;
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (spaceData?.length === 0) {
    return (
      <Card style={{ marginTop: '24px' }}>
        <FlexContent bottom="12">
          <HeadingIcon />
          <Typography>No Space Created</Typography>
        </FlexContent>
      </Card>
    );
  }

  // const location = useLocation();
  const disableSelection = false; // location.pathname.includes('planlist');

  const currentPlanLevel =
    subscriptionData.length > 0 ? findCurrentPlanLevel() : 0;
  const currentPlanName =
    subscriptionData.length > 0 ? subscriptionData[0].sku_name : '';
  const currentPlanSkuId =
    subscriptionData.length > 0 ? subscriptionData[0].sku_id : '';

  const getSupportedCameraCount = (supported_features: any) => {
    return supported_features
      .filter((item: any) => item.id.includes('camera_supported'))[0]
      .id.split('_')[0];
  };

  const filterByDeviceSupportCount = (el: TPlanList) => {
    const supportedCameraCount = getSupportedCameraCount(el.supported_features);
    console.log(supportedCameraCount);

    return (
      (supportedCameraCount === 'unlimited'
        ? 100
        : Number(supportedCameraCount)) >= selectedSpaceDeviceData.length
    );
  };

  // const filterOnlyByLevel = (el: IPlan) => el.level > currentPlanLevel;

  const filterByLevel = (el: TPlanList) =>
    el.level > currentPlanLevel && filterByDeviceSupportCount(el);

  // eslint-disable-next-line no-nested-ternary
  // const filterFunction =
  //   subscriptionData.length === 0 ? filterByDeviceSupportCount : filterByLevel;

  // const filterFunction = filterByDeviceSupportCount;

  const yearlyPlans = allPlans.filter(
    (el: TPlanList) => el.subscription_cycle === 12
  );
  const monthlyPlans = allPlans.filter(
    (el: TPlanList) => el.subscription_cycle === 1
  );

  const typeOfPlans = checked ? yearlyPlans : monthlyPlans;

  const isAnyActiveNonLinkedSubscription = subscriptionData.find(
    (item) =>
      item.id &&
      item.sku_id &&
      item.subscribe_status === 'Active' &&
      item.pay_status === 'Success' &&
      item.devices.length === 0
  );

  console.log(
    'isAnyActiveNonLinkedSubscription ',
    isAnyActiveNonLinkedSubscription
  );

  const createPurchaseId = (plan: TPlanList) => {
    // debugger;
    const payload: TPurchasePayload = {
      country: 'US',
      sku_id: plan.id,
      trial: canPurchaseFreeTrial,
      space_id: spaceData[0]?.id
    };
    // debugger;
    if (subscriptionData.length > 0) {
      payload['action'] = 'Upgrade';
      payload['previous_purchase_id'] = subscriptionData[0].id;
    }
    // debugger;
    axios
      .post(endpoints.createPurchaseIdUrl, payload)
      .then((res) => {
        // setPurchaseId(res.data.purchase_id);
        // if (isMobile) {
        // debugger;
        navigate('/payment-selections', {
          state: {
            isUpgrade: isUpgrade || payload['action'] === 'Upgrade',
            isFreeTrial: canPurchaseFreeTrial,
            planSelected: plan,
            purchaseId: res.data.purchase_id
          }
        });
        // }
        // else {
        //   openModal();
        // }
      })
      .catch((err) => console.log('purchases err', err));
  };

  const handlePlanSelection = (el: TPlanList) => {
    // debugger;
    console.log('handlePlanSelection', el);
    console.log(
      'isAnyActiveNonLinkedSubscription ',
      isAnyActiveNonLinkedSubscription
    );
    // debugger;

    // if (!pairingFlow && isAnyActiveNonLinkedSubscription && deviceId) {
    // debugger;
    // addCameraToSubscription(isAnyActiveNonLinkedSubscription);
    // } else {
    // debugger;
    createPurchaseId(el);
    // }
  };

  /**
   * ShowToogle will remove the toggle switch if number of plans after level filter for yeraly/monthly is zero
   */
  const showToggle = !(yearlyPlans.length === 0 || monthlyPlans.length === 0);
  return (
    !isLoading && (
      <>
        <Flex mtop="30" mbottom="16" direction="column">
          {canPurchaseFreeTrial && !isUpgrade && (
            <>
              <Typography>{`Choose the ${FREE_TRIAL_DURATION} day free trial plan:`}</Typography>
              {/* <Typography>Choose the 15 day free trial plan:</Typography> */}
              <Typography>
                {/* You will be charged once the 15 day Trial is completed */}
                {`You will be charged once the ${FREE_TRIAL_DURATION} day Trial is completed`}
              </Typography>
            </>
          )}
          {isUpgrade && subscriptionData[0].trial && (
            <>
              <Typography>Choose the free trial plan Upgrade:</Typography>
              <Typography>
                You will be charged once the Trial is completed
              </Typography>
            </>
          )}
          {isUpgrade && !subscriptionData[0].trial && (
            <>
              <Typography>Upgrade the plan that fits your needs</Typography>
              <Typography>
                You can choose any of the plans offered and subscribe to be able
                to
              </Typography>
              <Typography>choose more than one camera</Typography>
            </>
          )}
          {!canPurchaseFreeTrial && !isUpgrade && (
            <>
              <Typography>Choose the plan that fits your needs</Typography>
              <Typography>
                You can choose any of the plans offered and subscribe to be able
                to
              </Typography>
              <Typography>choose more than one camera</Typography>
            </>
          )}
        </Flex>
        {showToggle && (
          <Flex justify="center" mtop="40" mbottom="40" direction="column">
            <Flex>
              <PlanType active={checked}>Monthly</PlanType>
              <Switch
                sx={{
                  color: '#5FA4FB'
                }}
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <PlanType active={!checked}>Yearly</PlanType>
            </Flex>
            <Flex style={{ position: 'relative' }}>
              <DiscountInfo>*save 20%</DiscountInfo>
              <Image
                width="18"
                height="17"
                src={DiscountArrow}
                style={{ position: 'absolute', left: 75, bottom: 8 }}
              />
            </Flex>
          </Flex>
        )}
        <Flex
          style={{ overflow: 'scroll' }}
          justify={
            IsMobile() && showToggle && typeOfPlans.length > 1
              ? 'start'
              : 'center'
          }
        >
          {typeOfPlans.map((el: TPlanList) => {
            const { name, id, prices, supported_features } = el;

            const supportedCameraCount =
              getSupportedCameraCount(supported_features);

            const { currency } = prices[0];
            const isPopular = name === 'Multi-Standard';
            const showDiscount =
              prices[0]['price'] < prices[0]['original_price'];

            const disableButton = subscriptionData.length
              ? !filterByLevel(el)
              : false;
            console.log('disableButton  -  ', disableButton);

            // debugger;

            return (
              <PlanCard key={id} isPopular={isPopular}>
                {name === currentPlanName && id === currentPlanSkuId && (
                  <div className="ribbon">Your Plan</div>
                )}
                <FlexContainer>
                  <PlanHeading>{name}</PlanHeading>
                  {showDiscount && (
                    <Flex align="center" justify="center" mtop="4">
                      <OriginalCost>
                        {CURRENCY[currency]}
                        {prices[0]['original_price'] / 100}
                      </OriginalCost>
                      <DiscountDiff>
                        {CURRENCY[currency]}
                        {(prices[0]['original_price'] - prices[0]['price']) /
                          100}
                      </DiscountDiff>
                    </Flex>
                  )}

                  <FinalPlanCost>
                    {CURRENCY[currency]}
                    {prices[0]['price'] / 100}
                  </FinalPlanCost>

                  <hr style={{ borderColor: '#FFF' }} />
                  {supported_features.map((element: TSupportedFeature) => {
                    const icon = obj[element.id];
                    return (
                      <Flex align="center" key={element.id} mbottom="10">
                        <Image width="20" height="20" src={icon} />
                        <PlanDetailPoint>{element.description}</PlanDetailPoint>
                      </Flex>
                    );
                  })}
                  <CameraCount>
                    {`${supportedCameraCount} ${
                      supportedCameraCount === 1 ? 'camera only' : 'cameras'
                    }`}
                  </CameraCount>
                </FlexContainer>
                {!disableSelection && (
                  <Button
                    color="primary"
                    sx={{
                      marginTop: '24px',
                      // background: '#fff',
                      borderRadius: '25px',
                      borderWidth: '1.5px'
                    }}
                    variant="contained"
                    onClick={() => {
                      // debugger;
                      if (onPlanSelection) {
                        // debugger;
                        onPlanSelection(el);
                      } else {
                        // debugger;
                        handlePlanSelection(el);
                      }
                    }}
                    disabled={disableButton}
                  >
                    Select
                  </Button>
                )}
              </PlanCard>
            );
          })}
        </Flex>
      </>
    )
  );
}
