import axios from 'axios';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { SubscriptionPlanContent } from 'components/SubscriptionPlanContent/SubscriptionPlanContent';
import urls from 'configs/urls';
import OurPlans from 'pages/ourPlans';
import SubscriptionsDetails from 'pages/subscriptions/subscriptionDetails';
import SubscriptionList from 'pages/subscriptions/subscriptionList';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUser } from 'store/reducers/userReducers';
import { LogoutUser } from 'store/store';
import AccountDeletionRequest from 'views/AccountDeletion/request';
import VerifyDeleteRequest from 'views/AccountDeletion/verifyRequest';
import AddCameraInfo from 'views/AddCameraInfo/AddCameraInfo';
import AuthorizeDevice from 'views/AuthorizeDevice/AuthorizeDevice';
import AutoLogin from 'views/AutoLogin/AutoLogin';
import Bridge from 'views/Bridge';
import CameraSelection from 'views/CameraSelection/CameraSelection';
import Features from 'views/Features';
import LaunchApp from 'views/LaunchApp';
import NotFound from 'views/NotFound/NotFound';
import PaymentSuccess from 'views/PaymentSuccess/PaymentSuccess';
import SetupComplete from 'views/SetupComplete/SetupComplete';
import SkippingTrial from 'views/SkippingTrial/SkippingTrial';
import SubscriptionFunctions from 'views/SubscriptionFunctions/subscriptionFunctions';
import PaymentOptions from 'views/SubscriptionInfo/componenets/PaymentOptions';
import { SubscriptionDetails } from 'views/SubscriptionInfo/SubscriptionDetails';
import SubscriptionSummary from 'views/SubscriptionSummary/SubscriptionSummary';
import WhySubscribe from 'views/WhySubscribe/WhySubscribe';
import './App.css';

import * as amplitude from '@amplitude/analytics-browser';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import envConfig from 'configs/envConfig';
import { ConfigContext } from 'context';
import BasicUpgrade from 'pages/basicUpgrade';
import BuySubscription from 'pages/buySubscription';
import Dashboard from 'pages/dashboard';
import MultiCameraAdded from 'pages/multiCameraAdded';
import ReviewPurchase from 'pages/reviewPurchase';
import SingleCameraAdded from 'pages/singleCameraAdded';
import UpgradeSubscription from 'pages/upgradeSubscription';
import UserAccount from 'pages/userAccount';
import WhySkip from 'pages/whySkip/whySkip';
import { getPartnerConfiguration, getPartnerName } from 'partner_configuration';
import AppThemeProvider from 'themes/AppThemeProvider';
import AuthorizeDeviceFromApp from './components/AuthorizationFromApp';
import AddPaymentMethods from './components/BillingAndInvoices/addPaymentMethods';
import BillingAndInvoiceSummary from './components/BillingAndInvoices/billingAndInvoicesSummary';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import LoginScreen from './views/LoginScreen/LoginScreen';
// import fallback from './views/LaunchApp/fallback';

function App() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const url: any = window.location.origin;
  console.log('url::', url);
  const partnerName = getPartnerName(url);
  console.log(':: partnerName', partnerName);
  axios.defaults.baseURL = urls.baseUrl;
  axios.defaults.headers.common['Partner-ID'] = partnerName;
  axios.defaults.headers.common['Client-ID'] = 'web';
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const location = useLocation();
  const config = getPartnerConfiguration(url);
  console.log('config:: ', config);
  // test
  const theme = AppThemeProvider(partnerName);

  useEffect(() => {
    // amplitude
    const amplitudeKey = envConfig('amplitudeKey');
    if (amplitudeKey) amplitude.init(amplitudeKey);

    if (location.pathname.includes('authorize_device')) {
      return;
    }
    if (isAuthenticated) {
      dispatch(fetchUser());
    } else {
      LogoutUser();
    }
  }, []);

  return (
    <div className="App" style={{ height: '100vh' }}>
      <ErrorBoundary>
        <ConfigContext.Provider value={config}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="launchMobileApp" Component={LaunchApp} />
              {/* <Route path="launchApp" Component={fallback} /> */}
              <Route path="login" Component={LoginScreen} />
              <Route path="auto-login" Component={AutoLogin} />
              <Route path="authorize_device" Component={AuthorizeDevice} />
              <Route
                path="authorize_device_from_app"
                Component={AuthorizeDeviceFromApp}
              />
              <Route
                path="requestDeleteServices"
                Component={AccountDeletionRequest}
              />
              <Route
                path="verifyDeleteServices"
                Component={VerifyDeleteRequest}
              />
              <Route path="bridge" Component={Bridge} />
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" Component={Dashboard} />
                <Route path="/MySubscription" Component={SubscriptionDetails} />
                <Route path="/planlist" Component={SubscriptionPlanContent} />
                <Route path="/payment-selections" Component={PaymentOptions} />
                <Route path="/payment-success" Component={PaymentSuccess} />
                <Route path="/setupComplete" Component={SetupComplete} />
                <Route
                  path="/subscription-functions"
                  element={<SubscriptionFunctions />}
                />
                <Route path="/why-subscribe" element={<WhySubscribe />} />
                <Route path="/skipTrial" element={<SkippingTrial />} />
                <Route path="/camera-selection" element={<CameraSelection />} />
                <Route
                  path="/subscription-summary"
                  element={<SubscriptionSummary />}
                />
                <Route path="/promotion" Component={Features} />
                <Route
                  path="requestDeleteServices"
                  Component={AccountDeletionRequest}
                />
                <Route
                  path="verifyDeleteServices"
                  Component={VerifyDeleteRequest}
                />

                <Route
                  path="/singleCameraAdded"
                  element={<SingleCameraAdded />}
                />
                <Route
                  path="/multiCameraAdded"
                  element={<MultiCameraAdded />}
                />
                <Route path="/reviewPurchase" Component={ReviewPurchase} />
                <Route path="/ourPlans" Component={OurPlans} />
                <Route path="/addCameraInfo" Component={AddCameraInfo} />
                <Route path="/billings" Component={BillingAndInvoiceSummary} />
                <Route path="/billings/new" Component={AddPaymentMethods} />
                <Route path="/userAccount" Component={UserAccount} />
                <Route path="/buySubscription" Component={BuySubscription} />
                <Route path="/subscriptions" Component={SubscriptionList} />
                <Route
                  path="/subscription/:id"
                  Component={SubscriptionsDetails}
                />
                <Route path="/whySkipTrial" Component={WhySkip} />
                <Route path="/upgradeBasic" Component={BasicUpgrade} />
                <Route
                  path="/subscription/:id/upgrade"
                  Component={UpgradeSubscription}
                />
                <Route path="*" Component={NotFound} />
              </Route>
              <Route path="*" Component={LoginScreen} />
            </Routes>
          </ThemeProvider>
        </ConfigContext.Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
