/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { useEffect } from 'react';
import { setRequestHeaders, getLocalStorageItem } from 'utils/commonFunctions';
import { LogoutUser } from 'store/store';

const token = getLocalStorageItem('auth-token');
if (token) {
  setRequestHeaders(token);
}

const AxiosInterceptor = ({ children }) => {
  useEffect(() => {
    const reqInterceptor = (request) => {
      return request;
    };

    const resInterceptor = (response) => {
      return response;
    };

    const errInterceptor = (error) => {
      console.log('errInterceptor');
      if (error.response.status === 401 || error.response.status === 403) {
        console.log('errInterceptor perform logout');
        LogoutUser();
      }

      return Promise.reject(error);
    };

    const requestInterceptor = axios.interceptors.request.use(
      reqInterceptor,
      errInterceptor
    );

    const responseInterceptor = axios.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, []);

  return children;
};

export default axios;
export { AxiosInterceptor };
