import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import { Flex } from 'styles';
import { useNavigate } from 'react-router-dom';

const OfferContainer = styled.div`
  display: flex;
  padding: 16px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #d7eea1;
  margin-bottom: 24px;
`;

export default function WhySubscribe() {
  const navigate = useNavigate();
  return (
    <Flex direction="column" height="100%" justify="space-between">
      <Flex direction="column" align="start" style={{ width: '100%' }}>
        <Typography>Why Subscribe?</Typography>
        <OfferContainer>
          <Typography>Exclusive Offer</Typography>
          <Typography>Get a free trial Today!</Typography>
        </OfferContainer>
      </Flex>

      <Button
        size="large"
        variant="contained"
        color="primary"
        style={{
          width: '50%'
        }}
        onClick={() => navigate('/buySubscription')}
      >
        See Plans
      </Button>
    </Flex>
  );
}
