import { TUserResponseType } from 'typings/userTypes';
import axios from 'axios';
import { ApiResponse } from './HttpRequest';
import { endpoints } from './endpoints';

export async function GetUserDetails(): Promise<
  ApiResponse<TUserResponseType>
> {
  const response = await axios.get(endpoints.getUser);
  return response;
}

export async function GetOneTimeToken() {
  const response = await axios.get(endpoints.oneTimeToken);
  return response;
}
