import { Elements } from '@stripe/react-stripe-js';
import Stripe3DS from './Stripe';
import { stripePromise } from '../../configs/stripeConfig';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Authenticate3DS(props: any) {
  const {
    paymentOptionSelected,
    clientSecret,
    polling,
    setShowStripePaymentFailedPopUp,
    setStripeErrorResponse,
    setIsApiLoading,
    setTriggerStripePayment
  } = props;
  return (
    clientSecret && (
      <Elements stripe={stripePromise}>
        <Stripe3DS
          client_secret={clientSecret}
          paymentOptionSelected={paymentOptionSelected}
          polling={polling}
          setShowStripePaymentFailedPopUp={setShowStripePaymentFailedPopUp}
          setStripeErrorResponse={setStripeErrorResponse}
          setIsApiLoading={setIsApiLoading}
          setTriggerStripePayment={setTriggerStripePayment}
        />
      </Elements>
    )
  );
}

export default Authenticate3DS;
