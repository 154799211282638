/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex } from 'styles';
import { setRequestHeaders } from 'utils/commonFunctions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUser } from 'store/reducers/userReducers';
import CircularProgress from '@mui/material/CircularProgress';
import { TStatusMessages } from 'typings';

const ContentContainer = styled.div`
  font-size: 30px;
  margin: 20px;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const statusMessage: TStatusMessages = {
  authorizing: 'Authorizing Request...',
  token_expired: 'Link is expired or invalid. Please generate link again.'
};
function AutoLogin() {
  const [status, setStatus] = useState<string>('authorizing');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAppSelector((state) => state.user);
  const query = new URLSearchParams(location.search);
  const token = query.get('token') || '';

  useEffect(() => {
    if (token === '') {
      setStatus('token_expired');
      return;
    }
    setTimeout(() => {
      setRequestHeaders(token);
      dispatch(fetchUser());
    }, 1000);
  }, [token]);

  useEffect(() => {
    if (isLoading === 'succeeded' && isAuthenticated) {
      navigate('/dashboard');
    }
    if (isLoading === 'failed' && !isAuthenticated) {
      setStatus('token_expired');
    }
  }, [isAuthenticated, isLoading]);
  return (
    <Flex justify="center" align="center" height="100vh">
      <ContentContainer>
        {status === 'authorizing' && <CircularProgress size={30} />}
        {statusMessage[status]}
      </ContentContainer>
    </Flex>
  );
}
export default AutoLogin;
