import {
  Button,
  Card,
  Stack,
  Typography,
  styled as styledMUI
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useContext, useEffect } from 'react';
import { ConfigContext } from 'context';
import VideoIcon from 'assets/freeTrial/videoSlash.png';
import ShieldIcon from 'assets/freeTrial/shieldSlash.svg';
import FalseAlertIcon from 'assets/freeTrial/falseAlerts.svg';
import { FREE_TRIAL_DURATION } from 'utils/constants';

const ListItems = styledMUI('li')(({ theme }) => ({
  listStyleType: 'disc',
  listStylePosition: 'inside',
  '::marker': {
    color: theme.palette.background.orange
  }
}));

const Image = styled.img<{
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.borderRadius || 0}px;
`;

function WhySkip() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isFreeTrial, isUpgrade, deviceId } = location.state || {};
  console.log('whySkip -- isFreeTrial - ', isFreeTrial);
  const config = useContext(ConfigContext);
  const LivingRoomImg = config.skipFreeTrialLivingRoom;
  const TheftImg = config.skipFreeTrialTheft;
  const FalseAlertImg = config.skipFreeTrialFalseAlerts;

  useEffect(() => {
    // Disable scroll restoration to manually control scroll behavior
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack>
      <Stack>
        <Typography variant="h2" textAlign="center" color="background.orange">
          Proceed With Basic Camera <br />
          Configuration Only?
        </Typography>
      </Stack>
      <Card
        style={{
          border: '0.5px solid #F35D1D',
          borderRadius: '20px',
          padding: '12px 8px ',
          background: '#EDEDED',
          boxShadow: 'none',
          margin: '24px 0px',
          width: isMobile ? `${window.innerWidth - 40}px` : '335px',
          alignSelf: 'center'
        }}
      >
        <ul style={{ margin: '0px', paddingLeft: '12px', fontSize: '16px' }}>
          <ListItems>
            {`${FREE_TRIAL_DURATION} Day free trial without any charges`}
            {/* 15 Day free trial without any charges */}
          </ListItems>
          <ListItems>Cancel anytime during the trial period</ListItems>
          <ListItems>We notify a day before free trial ends</ListItems>
        </ul>
      </Card>
      <div style={{ position: 'relative', alignSelf: 'center' }}>
        <div
          style={{
            width: '175px',
            height: '72px',
            // margin: 'auto',
            borderRadius: '28px',
            opacity: '0.9',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: '#4389E133',
            backdropFilter: 'blur(40px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <p style={{ margin: '0', color: 'white', fontWeight: '500' }}>
            Snapshots
          </p>
        </div>
        <Image
          src={LivingRoomImg}
          width={isMobile ? `${window.innerWidth - 40}` : '335'}
          height="240"
          style={{
            alignSelf: 'center',
            maxWidth: '100%',
            borderRadius: '24px'
          }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: '12px',
          margin: '16px 0px'
        }}
      >
        <Image
          src={VideoIcon}
          width="24px"
          height="24px"
          style={{ maxWidth: '100%', borderRadius: '20px' }}
        />
        <Typography
          variant="Body1"
          color="text.secondary"
          sx={{ textAlign: 'center' }}
        >
          No Event Videos.
          <br />
          Only snapshots are captured and stored.
        </Typography>
      </div>

      <Image
        src={TheftImg}
        width={isMobile ? `${window.innerWidth - 40}` : '335'}
        height="240"
        style={{ alignSelf: 'center', maxWidth: '100%' }}
        borderRadius="20px"
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: '12px',
          margin: '16px 0px'
        }}
      >
        <Image
          src={ShieldIcon}
          width="24px"
          height="24px"
          style={{ maxWidth: '100%' }}
        />
        <Typography
          variant="Body1"
          color="text.secondary"
          sx={{ textAlign: 'center' }}
        >
          Preventing theft won&apos;t be possible.
          <br />
          No professional monitoring
        </Typography>
      </div>

      <Image
        src={FalseAlertImg}
        width={isMobile ? `${window.innerWidth - 40}` : '335'}
        height="240"
        style={{ alignSelf: 'center', maxWidth: '100%' }}
        borderRadius="20px"
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: '12px',
          margin: '16px 0px'
        }}
      >
        <Image
          src={FalseAlertIcon}
          width="24px"
          height="24px"
          style={{ maxWidth: '100%' }}
        />
        <Typography
          variant="Body1"
          color="text.secondary"
          sx={{ textAlign: 'center' }}
        >
          Frequent false motion alerts. <br />
          No descriptive information about events.
        </Typography>
      </div>

      <Stack pt={4} gap={2} pb={4}>
        {isFreeTrial ? (
          <>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{
                width: isMobile ? `${window.innerWidth - 40}px` : '335px',
                textTransform: 'capitalize',
                alignSelf: 'center'
              }}
              onClick={() =>
                navigate('/buySubscription', {
                  replace: true,
                  state: {
                    isFreeTrial,
                    isUpgrade: isUpgrade || false,
                    deviceId
                  }
                })
              }
            >
              Start Free Trial
            </Button>
            <Button
              size="large"
              variant="text"
              color="secondary"
              style={{
                width: isMobile ? `${window.innerWidth - 40}px` : '335px',
                textTransform: 'capitalize',
                border: 'none',
                alignSelf: 'center'
              }}
              onClick={() => {
                navigate('/skipTrial', {
                  state: {
                    isFreeTrial,
                    deviceId
                  }
                });
              }}
            >
              Continue with reduced protection
            </Button>
          </>
        ) : (
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={{
              width: isMobile ? `${window.innerWidth - 40}px` : '335px',
              textTransform: 'capitalize',
              alignSelf: 'center'
            }}
            onClick={() =>
              navigate('/buySubscription', {
                replace: true,
                state: {
                  isFreeTrial,
                  isUpgrade: isUpgrade || false,
                  deviceId
                }
              })
            }
          >
            Buy Plan
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
export default WhySkip;
