import { Flex } from 'styles';
import AddCameraFail from 'assets/shield.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import { useAppDispatch } from 'store/hooks';
import { useContext, useEffect } from 'react';
import { Button, styled as styledMUI, Typography } from '@mui/material';
import { IsMobile } from 'hooks/isMobile';
import { ReactComponent as PaymentSuccessIcon } from 'assets/PaymentSucessIcon.svg';
import { ConfigContext } from 'context';

const StyledImg = styledMUI('img')(({ theme }) => ({
  width: '250px',
  height: '250px',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '35%',
    maxHeight: '19vh'
  }
}));

const StyledDiv = styledMUI('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '320px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '6px'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '360px'
  }
}));

export default function PaymentSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const config = useContext(ConfigContext);

  const { isFreeTrial, isUpgrade, showSetupComplete, errorMessage } =
    location.state;

  useEffect(() => {
    dispatch(fetchAllSubscription());
  }, []);

  return (
    <StyledDiv>
      <Flex
        direction="column"
        height="100%"
        align="center"
        justify="space-between"
      >
        <Flex direction="column" align="center">
          <Typography>Payment</Typography>
          <PaymentSuccessIcon style={{ maxHeight: '18vh' }} />
          <Flex direction="column" justify="start">
            <Typography>The payment was successful</Typography>
            {isFreeTrial && !isUpgrade && (
              <Typography>Your free trial has been activated!</Typography>
            )}
            {isFreeTrial && isUpgrade && (
              <Typography>
                Your free trial upgrade has been activated!
              </Typography>
            )}
            {!isFreeTrial && !isUpgrade && (
              <Typography>Your subscription has been activated!</Typography>
            )}
            {!isFreeTrial && isUpgrade && (
              <Typography>
                Your Upgraded subscription has been activated!
              </Typography>
            )}
            {errorMessage !== '' && (
              <Flex>
                <Flex direction="column" align="baseline">
                  <StyledImg src={AddCameraFail} width="62" height="62" />
                  <Typography>
                    Sorry! We were unable add the device your subscription plan
                  </Typography>
                  <Typography>Please contact customer service via:</Typography>
                  <Flex align="center">
                    <Typography>Email: </Typography>
                    <Typography>{config.supportEmail}</Typography>
                  </Flex>
                  <Flex align="center">
                    <Typography>Phone number: </Typography>
                    <Typography>{config.phoneNumber}</Typography>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Button
          size="large"
          variant="contained"
          color="primary"
          style={{
            width: '90%',
            marginBottom: IsMobile() ? '10px' : '20px',
            marginTop: IsMobile() ? '10px' : '0px'
          }}
          onClick={() =>
            navigate(showSetupComplete ? '/setupComplete' : '/dashboard')
          }
        >
          Continue
        </Button>
      </Flex>
    </StyledDiv>
  );
}
