import styled from 'styled-components';

export const Flex = styled.div<{
  p?: string;
  ptop?: string;
  pbottom?: string;
  pleft?: string;
  pright?: string;
  m?: string;
  mtop?: string;
  mbottom?: string;
  mleft?: string;
  mright?: string;
  bgcolor?: string;
  radius?: string;
  justify?: string;
  align?: string;
  wrap?: string;
  flex?: string;
  direction?: string;
  height?: string;
  gap?: string;
}>`
  display: flex;
  height: ${(props) => props.height || 'auto'};
  flex-direction: ${(props) => props.direction || 'row'};
  padding: ${(props) => props.p || 0}px;
  padding-top: ${(props) => props.ptop || props.p || 0}px;
  padding-bottom: ${(props) => props.pbottom || props.p || 0}px;
  padding-left: ${(props) => props.pleft || props.p || 0}px;
  padding-right: ${(props) => props.pright || props.p || 0}px;
  margin: ${(props) => props.m || 0}px;
  margin-top: ${(props) => props.mtop || props.m || 0}px;
  margin-bottom: ${(props) => props.mbottom || props.m || 0}px;
  margin-left: ${(props) => props.mleft || props.m || 0}px;
  margin-right: ${(props) => props.mright || props.m || 0}px;
  background-color: ${(props) => props.bgcolor || 'transparent'};
  border-radius: ${(props) => props.radius || 0}px;
  justify-content: ${(props) => props.justify || 'unset'};
  align-items: ${(props) => props.align || 'center'};
  flex-wrap: ${(props) => props.wrap || 'inherit'};
  flex: ${(props) => props.flex || 'inherit'};
  gap: ${(props) => props.gap || 'unset'};
`;

export const Image = styled.img<{
  width: string;
  height: string;
  borderRadius?: string;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.borderRadius || 0}px;
`;
