/* eslint-disable import/no-unresolved */

import NunitoSansRegularWoff from 'themes/fonts/nunito-sans-regular.woff';
import NunitoSansRegularWoff2 from 'themes/fonts/nunito-sans-regular.woff2';

import NunitoSansSemiBoldWoff from 'themes/fonts/nunito-sans-semibold.woff';
import NunitoSansSemiBoldWoff2 from 'themes/fonts/nunito-sans-semibold.woff2';

import NunitoSansBoldWoff from 'themes/fonts/nunito-sans-bold.woff';
import NunitoSansBoldWoff2 from 'themes/fonts/nunito-sans-bold.woff2';

export const NunitoSansRegular = `
@font-face {
  font-family: 'Nunito Sans';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 400;
  src: local('Nunito Sans Regular'), local('NunitoSans-Regular'), local('Nunito Sans'), local('NunitoSans'), url(${NunitoSansRegularWoff2}) format('woff2'), url(${NunitoSansRegularWoff}) format('woff');
  unicodeRange: 'U+0020-017F,U+20AC';
}
`;

export const NunitoSansSemiBold = `
@font-face {
  font-family: 'Nunito Sans';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 600;
  src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'), local('Nunito Sans'), local('NunitoSans'), url(${NunitoSansSemiBoldWoff2}) format('woff2'), url(${NunitoSansSemiBoldWoff}) format('woff');
  unicodeRange: 'U+0020-017F,U+20AC'
}
`;

export const NunitoSansBold = `
@font-face {
  font-family: 'Nunito Sans';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 700;
  src: local('Nunito Sans Bold'), local('NunitoSans-Bold'), local('Nunito Sans'), local('NunitoSans'), url(${NunitoSansBoldWoff2}) format('woff2'), url(${NunitoSansBoldWoff}) format('woff');
  unicodeRange: 'U+0020-017F,U+20AC'
}
`;
