import {
  styled as styledMUI,
  Stack,
  Typography,
  IconButton
} from '@mui/material';
import { ReactComponent as InstavisonIcon } from 'assets/instaVision-icon.svg';
import { ReactComponent as InfoIcon } from 'assets/info-icon.svg';
import { TDeviceResponseType } from 'typings';
import PopUp from 'components/PopUp';
import CameraDetails from 'components/CameraDetails';
import { useState } from 'react';

const BlurContainer = styledMUI(Stack)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
background-image: linear-gradient(315deg, rgb(213 213 213 / 20%) 0%, rgb(255 255 255 / 20%) 100%);;
  border-radius: 0px;
  position: relative;
`;

function BlurBanner({ device }: { device: TDeviceResponseType }) {
  console.log('device---', device);
  const [showCameraDetails, setShowCameraDetails] = useState(false);

  const cameraDetailsCall = () => {
    setShowCameraDetails(true);
  };

  const clickClose = () => {
    setShowCameraDetails(false);
  };
  return (
    <>
      <BlurContainer>
        <InstavisonIcon style={{ marginLeft: '10px' }} />
        <Typography
          variant="h6"
          color="white"
          sx={{
            position: 'relative',
            flexGrow: 1,
            textAlign: 'center'
          }}
        >
          {device.name}
        </Typography>
        <IconButton
          style={{ right: '10px', outline: 'none' }}
          onClick={() => cameraDetailsCall()}
        >
          <InfoIcon />
        </IconButton>
      </BlurContainer>

      {showCameraDetails && (
        <PopUp isOpen cross onPopUpCloseClick={clickClose}>
          <CameraDetails device={device} />
        </PopUp>
      )}
    </>
  );
}

export default BlurBanner;
