/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetAllSpaces } from 'services/api/SpacesApi';
import { SpaceRole, TSpaceResponseList, TSpaceState } from 'typings';

export const fetchAllSpaces = createAsyncThunk(
  'spaces/fetchAllSpaces',
  async (payload, thunkAPI) => {
    try {
      const response = await GetAllSpaces();
      const ownerSpace = response.data.filter((item) => {
        return item.role === SpaceRole.OWNER;
      });
      return ownerSpace;
    } catch {
      return thunkAPI.rejectWithValue('failed');
    }
  }
);

const initialSpaceState: TSpaceState = {
  spaceData: {
    items: [],
    total_count: 0,
    next_page: ''
  },
  isLoading: false
};

export const spaceSlice = createSlice({
  name: 'space',
  initialState: initialSpaceState,
  reducers: {
    clearSpaceData: (state) => {
      state.spaceData = {
        items: [],
        total_count: 0,
        next_page: ''
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSpaces.fulfilled, (state, action) => {
        state.isLoading = false;
        state.spaceData = action.payload as unknown as TSpaceResponseList;
      })
      .addCase(fetchAllSpaces.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllSpaces.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

// Action creators are generated for each case reducer function
export const { clearSpaceData } = spaceSlice.actions;

export default spaceSlice.reducer;
