import { Stack, Typography } from '@mui/material';
import { ReactComponent as SetupCompletedIcon } from 'assets/setup-completed-icon.svg';
import LaunchAppButton from 'components/LaunchAppButton';

export default function SetupComplete() {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="column" alignItems="center" mt={2} mb={1}>
        <Typography variant="h3">Setup Completed</Typography>
        <Typography
          variant="Body1Medium"
          mt={1.5}
          color="text.shadedText"
          textAlign="center"
        >
          Your new camera is setup & ready to use. Open your app to view your
          camera
        </Typography>
      </Stack>

      <Stack
        direction="column"
        alignItems="flex-start"
        width="100%"
        marginBottom={2}
      >
        <Typography>TIPS:</Typography>
      </Stack>
      <SetupCompletedIcon />

      <Typography
        variant="h4"
        textAlign="center"
        marginTop={2.5}
        marginBottom={1.5}
      >
        Place the camera approximately 5-6 ft. above the ground.
      </Typography>
      <Typography
        variant="Body1"
        color="text.shadedText"
        textAlign="center"
        marginBottom={4}
      >
        For the best detections, place the camera at a medium height above the
        ground so it can see everything clearly.
      </Typography>
      <LaunchAppButton />
    </Stack>
  );
}
