import { useContext, useState } from 'react';
import logo from 'assets/logo_icon.png';
import { ReactComponent as AttentionIcon } from 'assets/attention.svg';
import { Flex } from 'styles';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  InputAdornment,
  FormControlLabel,
  TextField,
  Typography
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import axios from 'axios';
import { useFormik } from 'formik';
import { endpoints } from 'services/api/endpoints';
import AccountDeletionRequestFormValidationSchema from 'views/AccountDeletion/validation';
import { TDeleteAccountRequest } from 'typings';
import { ConfigContext } from 'context';
import { getPartnerName } from 'partner_configuration';

const PageContainer = styled.div`
  width: ${window.innerWidth < 450 ? window.innerWidth - 40 : 370}px;
  margin: 10px;
`;

const InputContainer = styled.div`
  position: relative;
  margin: 32px 0;
`;

const ListItem = styled.li`
  font-size: 14px;
  margin-bottom: 10px;
`;

function AccountDeletionRequest() {
  const [isRequestSubmited, setRequestSubmited] = useState(false);
  const config = useContext(ConfigContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const url: any = window.location.origin;
  const partnerName = getPartnerName(url);
  const Logo = config.logo;
  const axiosConfig = {
    headers: {
      'Partner-ID': partnerName
    }
  };
  const defaultValues = {
    email: '',
    confirmChecked: false
  };

  const handleFormSubmit = async (values: TDeleteAccountRequest) => {
    try {
      axios
        .post(
          endpoints.requestAccountDelete,
          { email: values.email },
          axiosConfig
        )
        .then((res) => {
          console.log('response', res);
          setRequestSubmited(true);
        })
        .catch((err) => {
          console.log('login err', err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: AccountDeletionRequestFormValidationSchema,
    onSubmit: handleFormSubmit
  });

  return (
    <Flex align="center" height="100vh" direction="column" mtop="30">
      <PageContainer>
        <Logo src={logo} width="60" height="60" />
        <Typography>Request Account Deletion</Typography>
        <Typography>
          {`Why delete your ${config.label} account with us ? If you are facing any
          technical or billing related issues, you can always reach us on
          ${config.phoneNumber} or write to us at ${config.supportEmail}`}
        </Typography>

        {!isRequestSubmited && (
          <InputContainer>
            <Flex justify="center" direction="column" gap="8px">
              <AttentionIcon />
              <Typography>IMPORTANT:</Typography>
            </Flex>
            <Typography>
              Before you proceed, please understand that deleting your account
              will have significant, irreversible consequences:
            </Typography>
            <ul>
              <ListItem>
                <b>All Data Removed:</b> Your devices, activities, history, and
                any recorded audio/video events will be permanently deleted.
              </ListItem>
              {partnerName === 'instaview' && (
                <ListItem>
                  <b>Payments & Refunds:</b> Any active plans will be canceled
                  immediately. You will not receive refunds for any payments
                  made, including monthly and yearly plans.
                </ListItem>
              )}
              <ListItem>
                <b>Irreversible Action:</b> This process cannot be reversed. If
                you create a new account with the same email, it will be a fresh
                start with no previous history.
              </ListItem>
            </ul>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                id="email"
                value={formik.values.email}
                label="Your account email with us"
                fullWidth
                sx={{ mt: '30px', mb: '20px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  )
                }}
                onChange={formik.handleChange}
                error={Boolean(formik?.errors?.email)}
              />
              <FormControlLabel
                sx={{
                  fontSize: '14px',
                  '& .MuiFormControlLabel-label': {
                    fontSize: '14px'
                  }
                }}
                control={
                  <Checkbox
                    id="confirmChecked"
                    checked={formik.values.confirmChecked}
                    onChange={formik.handleChange}
                  />
                }
                label={
                  partnerName === 'instaview'
                    ? 'By confirming, you acknowledge the permanent removal of your account and all associated data, understand there are no refunds for any purchases, and accept that this action is irreversible.'
                    : 'By confirming, you acknowledge the permanent removal of your account and all associated data and accept that this action is irreversible.'
                }
              />
              <Button
                sx={{
                  width: '100%',
                  mt: '30px'
                }}
                variant="contained"
                type="submit"
                disabled={
                  !formik.dirty || !formik.isValid || formik.isSubmitting
                }
              >
                Submit
              </Button>
            </form>
          </InputContainer>
        )}

        {isRequestSubmited && (
          <Typography style={{ marginTop: '42px' }}>
            {`Your request to delete your ${config.label} account is submited. You will
            receive an email, please click on the link in the email to verify
            the request.`}
          </Typography>
        )}
      </PageContainer>
    </Flex>
  );
}

export default AccountDeletionRequest;
