import styled from 'styled-components';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';

import CameraSnapshot from 'assets/promotion-snapshot.png';
import { FREE_TRIAL_DURATION } from 'utils/constants';

const Image = styled.img<{
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.borderRadius || 0}px;
`;

function BasicUpgrade() {
  const navigate = useNavigate();
  const location = useLocation();
  const { subscriptionObj, isFreeTrial, deviceId } = location.state || {};

  console.log('Features -- isFreeTrial - ', isFreeTrial);

  return (
    <Stack>
      <Stack>
        <Typography
          variant="h6"
          textAlign="center"
          marginBottom="27px"
          sx={{ color: (theme) => theme.palette.secondary.main }}
        >
          {subscriptionObj.trial
            ? `Your free trial period will end on ${dayjs(
                subscriptionObj?.trial_start_date
              )
                .add(FREE_TRIAL_DURATION, 'days')
                .format('MMM DD, YYYY')}.`
            : `Your subscription will expire on ${dayjs(
                subscriptionObj?.start_date
              )
                .add(subscriptionObj.subscription_cycle, 'months')
                .format('MMM DD, YYYY')}`}
        </Typography>

        <Chip
          label="upgrade"
          sx={{
            width: 'fit-content',
            alignSelf: 'center',
            textTransform: 'uppercase',
            backgroundColor: 'background.banner',
            color: 'text.contrastText',
            marginBottom: (theme) => theme.spacing(2),
            '& .MuiChip-label': {
              fontSize: (theme) => theme.typography.Body2.fontSize,
              fontWeight: (theme) => theme.typography.Body2.fontWeight,
              lineHeight: (theme) => theme.typography.Body2.lineHeight,
              letterSpacing: (theme) => theme.spacing(0.15)
            }
          }}
        />

        <Typography variant="h3" textAlign="center">
          Upgrade to premium features for a more powerful camera!
        </Typography>
        <Typography
          variant="Body1Medium"
          mt={1.5}
          mb={2}
          color="text.shadedText"
          textAlign="center"
        >
          Level up to a plan that fits your needs.
        </Typography>
      </Stack>
      <Image
        src={CameraSnapshot}
        borderRadius="15"
        width={isMobile ? `${window.innerWidth - 40}` : '335'}
        style={{ alignSelf: 'center', maxWidth: '100%', marginBottom: '27px' }}
      />
      <Stack pt={4} gap={2} pb={4}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          style={{
            width: '100%',
            textTransform: 'capitalize'
          }}
          onClick={() =>
            navigate(`/subscription/${subscriptionObj.id}/upgrade`, {
              replace: true,
              state: {
                isFreeTrial,
                isUpgrade: true,
                deviceId
              }
            })
          }
        >
          Upgrade
        </Button>
      </Stack>
    </Stack>
  );
}
export default BasicUpgrade;
