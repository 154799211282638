/* eslint-disable @typescript-eslint/no-explicit-any */

export enum PopupTypes {
  TYPE1 = 'type1',
  TYPE2 = 'type2'
}

export interface IProps_PopUp {
  isOpen?: boolean;
  type?: PopupTypes;
  cross?: boolean;
  children: any;
  onPopUpCloseClick?: () => any;
}
