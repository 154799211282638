import { object, string, ObjectSchema } from 'yup';
import { TAddCameraRequest } from 'typings';

const AddCameraRequestFormValidationSchema: ObjectSchema<TAddCameraRequest> =
  object({
    name: string().defined().trim().required(),
    location: string()
  });

export default AddCameraRequestFormValidationSchema;
