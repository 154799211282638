import { Card, Stack, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

function ExploreBanner() {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        borderRadius: '24px',
        padding: '16px',
        marginBottom: '10px',
        cursor: 'pointer',
        background: (theme) => theme.palette.background.banner,
        color: (theme) => theme.palette.text.contrastText
      }}
      onClick={() => {
        navigate('/subscriptions');
      }}
    >
      <Stack marginBottom="14px">
        <Typography variant="h3" paddingBottom="10px">
          Get more out of your camera.
        </Typography>

        <Typography variant="Body1SemiBold">
          Try our plans to make better experience
        </Typography>
      </Stack>
      <Stack flexDirection="row">
        <Typography variant="Body1">Explore Now</Typography>
        <ArrowForwardIcon sx={{ height: '18px', width: '18px' }} />
      </Stack>
    </Card>
  );
}

export default ExploreBanner;
