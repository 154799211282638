/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Flex, Image } from 'styles';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import logo from 'assets/logo_icon.png';
import { setRequestHeaders } from 'utils/commonFunctions';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from 'configs/firebaseConfig';
import { endpoints } from 'services/api/endpoints';
import { TStatusMessages } from 'typings';
import { Typography } from '@mui/material';
import { PRODUCT_NAME } from 'configs/constants';

const PageContainer = styled.div`
  width: ${window.innerWidth < 450 ? window.innerWidth - 40 : 370}px;
  margin: 10px;
`;

const ContentContainer = styled.div`
  font-size: 30px;
  margin: 80px 20px;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  gap: 22px;
`;

const statusMessage: TStatusMessages = {
  authorizing: 'Authorizing Request...',
  token_expired: 'Link is expired or invalid. Please generate link again.'
};

function VerifyDeleteRequest() {
  const [status, setStatus] = useState<string>('authorizing');
  const location = useLocation();
  const [customTokenVerified, setCustomTokenVerified] = useState(false);

  const query = new URLSearchParams(location.search);
  const token = query.get('token') || '';

  const deleteUserAccount = () => {
    axios
      .delete(endpoints.deleteUser)
      .then((res) => {
        if (res.status === 204) {
          setStatus('');
          setCustomTokenVerified(true);
        }
      })
      .catch((err) => {
        setStatus('token_expired');
        console.log('deleteUserAccount', err);
      });
  };

  const signInWithCustomTokenFunc = async (customToken: string) => {
    try {
      const res = await signInWithCustomToken(auth, customToken);
      const newToken = await res.user.getIdToken();
      setRequestHeaders(newToken);
      deleteUserAccount();
    } catch (err: any) {
      setStatus('token_expired');
      console.log(err);
    }
  };

  const verifyToken = (one_time_token: string) => {
    axios
      .post(endpoints.verifyToken, {
        one_time_token
      })
      .then((res) => {
        if (res.data.status === 'Valid') {
          signInWithCustomTokenFunc(res.data.custom_token);
        } else {
          setStatus('token_expired');
        }
      })
      .catch((err) => {
        setStatus('token_expired');
        console.log('verifyToken', err);
      });
  };

  useEffect(() => {
    if (token === '') {
      setStatus('token_expired');
      return;
    }
    if (!customTokenVerified) {
      verifyToken(token);
    }
  }, [token]);

  return (
    <Flex direction="column" align="center" height="100vh" mtop="30">
      <PageContainer>
        <ContentContainer>
          {!customTokenVerified && status === 'authorizing' && (
            <CircularProgress size={30} />
          )}
          {!customTokenVerified && statusMessage[status]}
        </ContentContainer>
        {customTokenVerified && (
          <>
            <Image src={logo} width="60" height="60" />
            <Typography>Verify Account Deletion</Typography>
            <Typography style={{ marginTop: '42px' }}>
              {`Your request to delete your ${PRODUCT_NAME} account is verified. Our
              team will review and process your request.`}
            </Typography>
            <Typography>{`We're sorry to see you go :(`}</Typography>
          </>
        )}
      </PageContainer>
    </Flex>
  );
}
export default VerifyDeleteRequest;
