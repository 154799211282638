/* eslint-disable react/function-component-definition */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Chip, Stack, Typography } from '@mui/material';
import InstaCard from 'components/InstaCard';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import InstaSpinner from 'components/InstaSpinner';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import { TSubScriptionState } from 'typings';

function SubscriptionList() {
  const dispatch = useAppDispatch();
  const { subscriptionData, isLoading } = useAppSelector(
    (state) => (state.subscription as TSubScriptionState) || []
  );

  const navigate = useNavigate();
  const handlePlanClick = (id: string) => {
    navigate(`/subscription/${id}`);
  };

  const fetchSubscriptionDetails = async () => {
    dispatch(fetchAllSubscription());
  };

  useEffect(() => {
    fetchSubscriptionDetails();
  }, []);

  if (isLoading) {
    return <InstaSpinner withWrapper />;
  }

  if (!isLoading && subscriptionData?.length) {
    return (
      <InstaCard>
        {subscriptionData?.map((subscriptionInfo) => {
          return (
            <Stack
              alignItems="center"
              justifyContent="space-between"
              direction="row"
              key={subscriptionInfo?.id}
              onClick={() => handlePlanClick(subscriptionInfo?.id)}
              sx={{ cursor: 'pointer', padding: '0 10px' }}
            >
              <div>
                <Typography variant="h4" sx={{ marginBottom: '8px' }}>
                  {subscriptionInfo?.space_name}
                </Typography>
                <Chip
                  label={subscriptionInfo?.sku_name}
                  sx={{
                    background: (theme) =>
                      subscriptionInfo?.sku_name === 'Basic'
                        ? theme.palette.background.yellow
                        : theme.palette.gradient.green,
                    color: (theme) => theme.palette.text.contrastText
                  }}
                />
              </div>
              <div>
                <ChevronRightIcon />
              </div>
            </Stack>
          );
        })}
      </InstaCard>
    );
  }

  if (!isLoading && subscriptionData?.length === 0) {
    navigate('/buySubscription');
  }

  return null;
}

export default SubscriptionList;
