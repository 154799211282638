/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import { Stack, Typography, Chip } from '@mui/material';

import { ReactComponent as CameraIcon } from 'assets/camera-icon.svg';
import { ReactComponent as BillIcon } from 'assets/card-coin.svg';
import { ReactComponent as PaymentMethodIcon } from 'assets/card.svg';
import { ReactComponent as PaymentIcon } from 'assets/dollar-circle.svg';

import { getSubscriptionDetails } from 'services/api/subscriptionApi';

import InstaCard from 'components/InstaCard';
import InstaSpinner from 'components/InstaSpinner';
import LaunchAppButton from 'components/LaunchAppButton';
import { SUBSCRIPTION_TYPE } from 'typings/purchaseTypes';

function SingleCameraAdded() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentSubscriptionObj, setCurrentSubscriptionObj] = useState<any>({});

  const location = useLocation();
  const { subscriptionObj } = location.state || {};

  useEffect(() => {
    setIsLoading(true);

    getSubscriptionDetails()
      .then((res) => {
        const found = res.data.find((item) => item.id === subscriptionObj.id);

        setCurrentSubscriptionObj(found);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <InstaSpinner withWrapper />;
  }

  if (!isLoading) {
    return (
      <>
        <Typography variant="h2" textAlign="center" marginBottom="30px">
          {`Your Camera is now covered by ${subscriptionObj.sku_name} Plan!`}
        </Typography>

        <Stack marginBottom="10px">
          <Typography variant="h3" marginBottom="16px">
            CAMERA COVERAGE
          </Typography>

          <InstaCard>
            {[
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                key="000-11"
              >
                <Stack direction="row" alignItems="center" gap={2}>
                  <CameraIcon />
                  <Stack direction="column">
                    <Typography variant="h4">
                      {currentSubscriptionObj.devices[0].name}
                    </Typography>
                    <Typography
                      variant="Body1Medium"
                      sx={{ color: (theme) => theme.palette.primary.main }}
                    >
                      Outdoor 1080p
                    </Typography>
                  </Stack>
                </Stack>
                <Chip
                  label={subscriptionObj.sku_name}
                  sx={{
                    background: (theme) =>
                      subscriptionObj?.sku_name === 'Basic'
                        ? theme.palette.background.yellow
                        : theme.palette.gradient.green,
                    color: (theme) => theme.palette.text.contrastText
                  }}
                />
              </Stack>
            ]}
          </InstaCard>
        </Stack>
        {subscriptionObj?.subscription_type !== SUBSCRIPTION_TYPE.PREVIEW && (
          <>
            <Typography variant="h3" marginBottom="16px">
              PAYMENT SUMMARY
            </Typography>
            <InstaCard>
              <Stack
                direction="column"
                marginBottom="20px"
                paddingLeft="10px"
                gap="5px"
              >
                <Stack direction="row">
                  <PaymentIcon />
                  <Typography variant="h4" marginLeft="10px">
                    Payment
                  </Typography>
                </Stack>
                <Typography variant="Body1">
                  {`Next Payment $${
                    subscriptionObj.next_bill_amount / 100
                  } On ${dayjs(subscriptionObj?.next_billing_date).format(
                    'MMM DD, YYYY'
                  )}`}
                </Typography>
              </Stack>

              <Stack
                direction="column"
                margin="20px 0px"
                paddingLeft="10px"
                gap="5px"
              >
                <Stack direction="row">
                  <BillIcon />
                  <Typography variant="h4" marginLeft="10px">
                    Bill
                  </Typography>
                </Stack>
                <Typography variant="Body1">{`Billed ${
                  subscriptionObj.subscription_cycle === 1
                    ? 'Monthly'
                    : 'Yearly'
                }`}</Typography>
              </Stack>

              <Stack
                direction="column"
                marginTop="20px"
                paddingLeft="10px"
                gap="5px"
              >
                <Stack direction="row">
                  <PaymentMethodIcon />
                  <Typography variant="h4" marginLeft="10px">
                    Payment Method
                  </Typography>
                </Stack>
                <Typography variant="Body1">
                  {`Charge to ${subscriptionObj?.card_details?.brand} Ending in ${subscriptionObj?.card_details?.last4}`}
                </Typography>
              </Stack>
            </InstaCard>
          </>
        )}
        <LaunchAppButton />
      </>
    );
  }
  return null;
}

export default SingleCameraAdded;
