import { Stack } from '@mui/material';
import { useContext } from 'react';
import { ConfigContext } from 'context';

export default function LaunchApp() {
  const config = useContext(ConfigContext);

  return (
    <Stack alignItems="center" justifyContent="center" height="100vh">
      <h2>
        <a href={`${config.universalLink}/launchApp`}>
          Tap here to open the app
        </a>
      </h2>
    </Stack>
  );
}
