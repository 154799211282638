import { Stack, Button, Typography, Box } from '@mui/material';
import PopUp from 'components/PopUp';
import { useContext } from 'react';
import { ConfigContext } from 'context';

interface TProps__PaymentFailed {
  showPaymentFailedPopUp: boolean;
  handlePaymentFailedTryAgainClick: () => void;
  paymentErrorMessage?: string | null;
}

function PaymentFailed(props: TProps__PaymentFailed) {
  const {
    handlePaymentFailedTryAgainClick,
    showPaymentFailedPopUp,
    paymentErrorMessage
  } = props;
  const config = useContext(ConfigContext);
  const PaymentFailedIcon = config.paymentFailed;
  return (
    <PopUp isOpen={showPaymentFailedPopUp}>
      <>
        <Stack direction="column" alignItems="center" marginBottom="30px">
          <Stack paddingBottom="12px">
            <PaymentFailedIcon width="300" height="250" />
          </Stack>
          <Typography variant="h2" paddingBottom="12px">
            Oh Snap!
          </Typography>
          <Box textAlign="center">
            {paymentErrorMessage ? (
              <Typography>{paymentErrorMessage}</Typography>
            ) : (
              <Typography variant="h6">
                Looks like there was some trouble processing your payment.
                Please wait for some time and try again.
              </Typography>
            )}
          </Box>
        </Stack>
        <Stack direction="column" alignItems="Stretch" marginBottom="6px">
          <Button
            variant="contained"
            color="inherit"
            sx={{
              marginBottom: '16px'
            }}
            onClick={handlePaymentFailedTryAgainClick}
          >
            Try Again
          </Button>
        </Stack>
      </>
    </PopUp>
  );
}
export default PaymentFailed;
