import { Flex } from 'styles';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { useContext } from 'react';
import { ConfigContext } from 'context';

const HeadingIcon = styled.div`
  width: 16px;
  height: 32px;
  border-radius: 4px;
  background: #d7eea1;
  margin-right: 16px;
`;

export function CustomerServiceContent() {
  const config = useContext(ConfigContext);
  return (
    <>
      <Flex justify="space-between" pbottom="24">
        <Flex>
          <HeadingIcon />
          <Typography>Cancel Subscription</Typography>
        </Flex>
      </Flex>
      <Typography>
        Please contact customer service to cancel your subscription via:
      </Typography>
      <Flex align="center">
        <Typography>Email: </Typography>
        <Typography>{config.supportEmail}</Typography>
      </Flex>

      <Flex align="center">
        <Typography>Phone number: </Typography>
        <Typography>{config.phoneNumber}</Typography>
      </Flex>
    </>
  );
}
