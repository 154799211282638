/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/function-component-definition */
import { SUBSCRIBE_STATUS } from 'configs/constants';
import { useState } from 'react';
import CameracIcon from 'assets/camera.png';
import CalendarcIcon from 'assets/calendar.png';
import styled from 'styled-components';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography
} from '@mui/material';
import { Flex, Image } from 'styles';
import dayjs from 'dayjs';
import { IsMobile } from 'hooks/isMobile';
import { useNavigate } from 'react-router-dom';
import {
  TStatusMessages,
  TModalType,
  TProps_SubscriptionInfoCard,
  TPlanList,
  TSupportedFeature
} from 'typings';
import CameraIcon from 'assets/camera_icon.png';
import Alarm from 'assets/alarm.png';
import Notification from 'assets/notification-bing.png';
import CloudAdd from 'assets/cloud-add.png';
import Monitoring from 'assets/eye.png';
import Sharing from 'assets/people.png';
import Support from 'assets/support.png';
import CloudStorage from 'assets/cloud-storage.png';
import VideoCameraIcon from 'assets/video-camera.png';
import { endpoints } from 'services/api/endpoints';
import axios from 'axios';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import { updateFreeTrialEligibility } from 'store/reducers/userReducers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FREE_TRIAL_DURATION } from 'utils/constants';

const HeadingIcon = styled.div`
  width: 16px;
  height: 32px;
  border-radius: 4px;
  background: #d7eea1;
  margin-right: 16px;
`;

const Card = styled.div<{
  color?: string;
  padding?: string;
  radius?: string;
  shadow?: boolean;
}>`
  padding: ${(props) => props.padding || 0}px;
  background-color: ${(props) => props.color || '#ffff'};
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: ${(props) => props.radius || 0}px;
  box-shadow: ${(props) =>
    props.shadow
      ? `    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12)`
      : 'none'};
`;

const CameraID = styled.div`
  color: #000;
  line-height: 32px;
  font-size: 14px;
  font-weight: 300;
  margin-left: 12px;
  margin-right: 35px;
`;

const Label = styled.div`
  color: #8c8a94;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-right: 5px;
`;

const Value = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
`;

const EndDate = styled(Value)`
  color: #fe832d;
`;

const PaymentInfoLabel = styled(Label)`
  font-size: 13px;
  line-height: 16px;
`;

const PaymentInfoValue = styled(Value)`
  font-size: 42px;
  line-height: 48px;
`;

const FeatureDetails = styled.div`
  border-radius: 12px;
  border: 1px solid #e5ebec;
  background: #f0f4f4;
  padding: 12px 20px;
  width: 550px;
  margin-bottom: 10px;
`;

const CameraCount = styled.div`
  color: #388efa;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 12px;
  text-transform: capitalize;
`;

const StartDate = styled.div`
  color: #9ca8b0;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px; /* 160% */
`;

const PlanDetailPoint = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 10px;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
`;

const obj: TStatusMessages = {
  basic_features: CameraIcon,
  user_sharing: Sharing,
  enhanced_motion: Notification,
  '30_days_event': CloudStorage,
  enhanced_support: Support,
  self_monitoring_send_help: Alarm,
  active_monitoring: Monitoring,
  '6_months_alarm': CloudAdd,
  unlimited_camera_supported: VideoCameraIcon,
  '1_camera_supported': VideoCameraIcon
};

const ModalType: TModalType[] = [
  { type: 'ADD_OR_REMOVE_CAMERA', heading: 'Add Camera' },
  { type: 'MODIFY', heading: 'Upgrade Subscription' },
  { type: 'CANCEL', heading: 'Cancel Subscription' },
  { type: 'ADD_OR_REMOVE_CAMERA', heading: 'Remove Camera' }
];

export const SubscriptionInfoCard = (props: TProps_SubscriptionInfoCard) => {
  const {
    openModal,
    subscriptionInfo,
    setSelectedSubscription,
    plans,
    onCancelFreeTrial
  } = props;
  console.log('plans -- ', plans);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);

  const isMobile = IsMobile();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const spacesList = useAppSelector((state) => state.space.spaceData.items);

  const highestLevel = plans[plans.length - 1]['level'];
  let level = 0;
  plans.forEach((el: TPlanList) => {
    if (el?.id === subscriptionInfo?.sku_id) {
      level = Number(el.level);
    }
  });
  const showModify = highestLevel === level;

  const getSpaceNameFromSpaceId = (spaceId: string) => {
    const found = spacesList?.find((item: any) => item.id === spaceId);

    return found?.address?.street || '';
  };

  const cancelFreeTrial = () => {
    const url = `${endpoints.subscriptionBuyUrl}/${subscriptionInfo.id}`;
    console.log('delete', url);
    axios
      .delete(url)
      .then((res) => {
        console.group('delte subscription', res);
        setShowConfirmCancel(false);
        setSelectedSubscription(subscriptionInfo);
        dispatch(fetchAllSubscription());
        dispatch(updateFreeTrialEligibility(false));
        onCancelFreeTrial();
      })
      .catch((err) => {
        console.log('onCancel Free Trial', err);
      });
  };

  const onCancel = () => {
    if (subscriptionInfo.trial) {
      setShowConfirmCancel(true);
    } else {
      openModal(ModalType[2]['type']);
    }
  };

  return (
    <>
      <Card
        color={isMobile ? '#fffff' : '#fcfcfc'}
        padding={isMobile ? '0' : '24'}
        radius="24"
        shadow={!isMobile}
      >
        <Flex
          mbottom="20"
          wrap="wrap"
          justify="space-between"
          align={isMobile ? 'baseline' : 'center'}
          direction={isMobile ? 'column' : 'row'}
        >
          <Flex wrap="wrap">
            <HeadingIcon />
            <Typography>
              {subscriptionInfo.trial && 'Free Trial - '}{' '}
              {subscriptionInfo.sku_name}
            </Typography>
            {!isMobile && (
              <StartDate>
                started from{' '}
                {dayjs(
                  subscriptionInfo?.start_date
                    ? subscriptionInfo.start_date
                    : subscriptionInfo?.trial_start_date
                ).format('YYYY.MM.DD')}
              </StartDate>
            )}
          </Flex>
          <Flex>
            {isMobile && (
              <StartDate>
                started from{' '}
                {dayjs(
                  subscriptionInfo?.start_date
                    ? subscriptionInfo.start_date
                    : subscriptionInfo?.trial_start_date
                ).format('YYYY.MM.DD')}
              </StartDate>
            )}
          </Flex>
          {subscriptionInfo.subscribe_status === SUBSCRIBE_STATUS.Active &&
            !isMobile && (
              <Flex
                mtop={isMobile ? '20' : '0'}
                style={{ width: isMobile ? '100%' : 'auto' }}
              >
                {subscriptionInfo.sku_name === 'Basic' && false && (
                  <Button
                    color="primary"
                    sx={{
                      marginRight: isMobile ? '0px' : '10px',
                      marginBottom: isMobile ? '10px' : '0px'
                    }}
                    variant="contained"
                    onClick={() => {
                      setSelectedSubscription(subscriptionInfo);
                      openModal(ModalType[0]['type']);
                    }}
                  >
                    + Add Camera
                  </Button>
                )}
                {!showModify && (
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      width: isMobile ? '100%' : 'auto',
                      marginRight: isMobile ? '0px' : '10px',
                      marginBottom: isMobile ? '10px' : '0px'
                    }}
                    onClick={() => {
                      if (isMobile) {
                        navigate('/buySubscription', {
                          state: {
                            isFreeTrial: subscriptionInfo.trial,
                            isUpgrade: true,
                            selectedSubscription: subscriptionInfo
                          }
                        });
                      } else {
                        setSelectedSubscription(subscriptionInfo);
                        openModal(ModalType[1]['type']);
                      }
                    }}
                  >
                    Modify{' '}
                    {subscriptionInfo.trial ? 'Free trial' : 'Subscription'}
                  </Button>
                )}
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    width: isMobile ? '100%' : 'auto',
                    marginRight: isMobile ? '0px' : '10px',
                    marginBottom: isMobile ? '10px' : '0px'
                  }}
                  onClick={onCancel}
                >
                  Cancel{' '}
                  {subscriptionInfo.trial ? 'Free trial' : 'Subscription'}
                </Button>
              </Flex>
            )}
        </Flex>
        <Typography>
          {getSpaceNameFromSpaceId(subscriptionInfo.space_id)}
        </Typography>

        <Flex justify="space-between" wrap="wrap">
          <Flex
            direction={isMobile ? 'column' : 'row'}
            align={isMobile ? 'start' : 'center'}
          >
            {subscriptionInfo.devices.map((item) => (
              <Flex key={item.id} mbottom="20">
                <Image src={CameracIcon} width="22" height="30" />
                <TextContainer>
                  <Typography>{item.name || 'Camera Name'}</Typography>
                  {item.id && <CameraID>Camera ID - {item.id}</CameraID>}
                </TextContainer>
              </Flex>
            ))}
          </Flex>

          {subscriptionInfo.devices.length > 0 &&
            false &&
            subscriptionInfo.sku_name === 'Basic' && (
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  textTransform: 'capitalize'
                }}
                onClick={() => {
                  setSelectedSubscription(subscriptionInfo);
                  openModal(ModalType[3]['type']);
                }}
              >
                Remove Camera{' '}
              </Button>
            )}
        </Flex>
        <FeatureDetails style={{ width: isMobile ? '100%' : '550px' }}>
          <Typography>You are secured by our Plan</Typography>
          <Flex wrap="wrap" justify="space-between">
            {subscriptionInfo.supported_features.map(
              (element: TSupportedFeature) => {
                const icon = obj[element.id];
                return (
                  <Flex
                    align="center"
                    key={element.id}
                    mbottom="10"
                    style={{ width: isMobile ? '100%' : '50%' }}
                    wrap="wrap"
                  >
                    {icon && <Image width="20" height="20" src={icon} />}
                    <PlanDetailPoint>{element.description}</PlanDetailPoint>
                  </Flex>
                );
              }
            )}
          </Flex>

          <CameraCount>
            {`${subscriptionInfo?.supported_features
              .filter((item) => item.id.includes('camera_supported'))[0]
              .id.split('_')[0]} camera`}
          </CameraCount>
        </FeatureDetails>
        <Flex>
          <Typography>
            {subscriptionInfo?.start_date ? 'Subscription' : 'Free trial'} for{' '}
          </Typography>
          <Value>
            {subscriptionInfo.sku_name}
            {subscriptionInfo?.subscription_cycle === 1
              ? ' Monthly'
              : ' Yearly'}
          </Value>
        </Flex>
        <Flex
          pbottom="20"
          mtop="15"
          direction={isMobile ? 'column' : 'row'}
          align={isMobile ? 'baseline' : 'center'}
        >
          <Flex
            direction={isMobile ? 'column' : 'row'}
            align={isMobile ? 'flex-start' : 'baseline'}
          >
            {subscriptionInfo.trial && subscriptionInfo?.trial_start_date && (
              <>
                <Typography>
                  Free trial started{' '}
                  <span style={{ fontWeight: 700, color: '#000' }}>
                    {dayjs(subscriptionInfo?.trial_start_date).format(
                      'YYYY.MM.DD'
                    )}
                  </span>
                </Typography>
                <Typography>
                  ends on{' '}
                  <span style={{ fontWeight: 700, color: '#000' }}>
                    {dayjs(subscriptionInfo?.trial_start_date)
                      .add(FREE_TRIAL_DURATION, 'day')
                      .format('YYYY.MM.DD')}
                  </span>
                </Typography>
              </>
            )}
            {!subscriptionInfo.trial && subscriptionInfo?.start_date && (
              <Typography>
                Subscription started{' '}
                <span style={{ fontWeight: 700, color: '#000' }}>
                  {dayjs(
                    subscriptionInfo?.start_date
                      ? subscriptionInfo.start_date
                      : subscriptionInfo?.trial_start_date
                  ).format('YYYY.MM.DD')}
                </span>
              </Typography>
            )}
          </Flex>
          {subscriptionInfo?.subscribe_status ===
            SUBSCRIBE_STATUS.Cancelled && (
            <Flex mleft={isMobile ? '0' : '15'}>
              <Typography>Subscription end</Typography>
              <EndDate>
                {dayjs(subscriptionInfo?.start_date)
                  .add(subscriptionInfo.subscription_cycle, 'months')
                  .format('YYYY.MM.DD')}
              </EndDate>
            </Flex>
          )}
        </Flex>
        <Card padding="8" color="#F4F4F4" radius="20">
          <Card padding="20" radius="12">
            <Flex
              direction={isMobile ? 'column' : 'row'}
              align={isMobile ? 'baseline' : 'center'}
              gap="20px"
            >
              <Flex bgcolor="#F4F5F5" radius="48" mright="30">
                <Image src={CalendarcIcon} width="24" height="24" />
              </Flex>
              {subscriptionInfo.subscribe_status === SUBSCRIBE_STATUS.Active ? (
                <>
                  <Flex
                    direction="column"
                    mright="30"
                    align={isMobile ? 'baseline' : 'center'}
                  >
                    <PaymentInfoLabel>Next Payment</PaymentInfoLabel>
                    <PaymentInfoValue>
                      {dayjs(subscriptionInfo.next_billing_date || '').format(
                        'YYYY.MM.DD'
                      )}
                    </PaymentInfoValue>
                  </Flex>
                  <Flex
                    direction="column"
                    mright="30"
                    align={isMobile ? 'baseline' : 'center'}
                  >
                    <PaymentInfoLabel>Amount</PaymentInfoLabel>
                    <PaymentInfoValue>
                      $
                      {subscriptionInfo.next_bill_amount &&
                        subscriptionInfo.next_bill_amount / 100}
                    </PaymentInfoValue>
                  </Flex>
                  <Flex
                    direction="column"
                    mright="30"
                    align={isMobile ? 'baseline' : 'center'}
                  >
                    <PaymentInfoLabel>Last payment amount</PaymentInfoLabel>
                    <PaymentInfoValue>
                      ${subscriptionInfo.paid_amount / 100}
                    </PaymentInfoValue>
                  </Flex>
                </>
              ) : (
                <Flex
                  direction="column"
                  mright="30"
                  align={isMobile ? 'baseline' : 'center'}
                >
                  <PaymentInfoLabel>End Subscription</PaymentInfoLabel>
                  <PaymentInfoValue>
                    {dayjs(subscriptionInfo.end_date || '').format('MM.DD')}
                  </PaymentInfoValue>
                </Flex>
              )}
            </Flex>
          </Card>
        </Card>
        {isMobile &&
          subscriptionInfo.subscribe_status === SUBSCRIBE_STATUS.Active && (
            <>
              {!showModify && (
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    marginTop: '15px'
                  }}
                  onClick={() => {
                    if (isMobile) {
                      navigate('/buySubscription', {
                        state: {
                          isFreeTrial: subscriptionInfo.trial,
                          isUpgrade: true,
                          selectedSubscription: subscriptionInfo
                        }
                      });
                    } else {
                      setSelectedSubscription(subscriptionInfo);
                      openModal(ModalType[1]['type']);
                    }
                  }}
                >
                  Modify{' '}
                  {subscriptionInfo.trial ? 'Free trial' : 'Subscription'}
                </Button>
              )}
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  marginTop: '15px'
                }}
                onClick={onCancel}
              >
                Cancel {subscriptionInfo.trial ? 'Free trial' : 'Subscription'}
              </Button>
            </>
          )}
      </Card>

      {showConfirmCancel && (
        <Dialog
          PaperProps={{
            style: {
              padding: '10px 0px'
            }
          }}
          onClose={() => setShowConfirmCancel(false)}
          open
        >
          <Button
            sx={{
              position: 'absolute',
              right: '20px',
              top: '20px',
              padding: '0px',
              width: '20px',
              minWidth: '20px',
              height: '20px',
              border: 'none',
              outline: 'none'
            }}
            onClick={() => setShowConfirmCancel(false)}
          >
            <CloseOutlinedIcon />
          </Button>

          <Typography>Confirm Free Trial Cancellation</Typography>
          <DialogContent dividers>
            <Typography>
              Are you sure, you want to cancel the subscription under free trial
              period ? Please confirm.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ gap: '24px', padding: '16px 24px' }}>
            <Button
              variant="contained"
              sx={{ height: '40px' }}
              autoFocus
              onClick={() => setShowConfirmCancel(false)}
            >
              Keep
            </Button>
            <Button
              variant="outlined"
              sx={{ height: '40px' }}
              onClick={cancelFreeTrial}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
