import { Stack, Button, Typography } from '@mui/material';
import { useAppSelector } from 'store/hooks';

import { TSubscriptionType } from 'typings';
import PopUp from 'components/PopUp';
import { PopupTypes } from 'typings/popUpTypes';
import { calculateRemainingDays } from 'utils/commonFunctions';

function CancleSubscription({
  isCancelModalOpen,
  onPopUpCloseClick,
  onBackClick,
  onFreeTrialCancelClick
}: {
  isCancelModalOpen: boolean;
  onPopUpCloseClick: () => unknown;
  onBackClick: () => unknown;
  onFreeTrialCancelClick: () => unknown;
}) {
  const subscriptionData = useAppSelector<TSubscriptionType[]>(
    (state) => state.subscription.subscriptionData
  );

  const remainingDays = subscriptionData[0]?.trial_start_date
    ? calculateRemainingDays(subscriptionData[0]?.trial_start_date)
    : '';

  return (
    <PopUp
      isOpen={isCancelModalOpen}
      type={PopupTypes.TYPE2}
      onPopUpCloseClick={onPopUpCloseClick}
    >
      <Stack
        direction="column"
        alignItems="center"
        textAlign="center"
        marginBottom="30px"
      >
        <Typography variant="h2" paddingBottom="12px">
          Are you sure you want to end your subscription?
        </Typography>
        <Typography variant="Body1Medium" paddingBottom="12px">
          If you confirm and finalize your cancellation now, all features will
          be unavailable starting today.
        </Typography>
        {remainingDays !== '' && remainingDays > 0 && (
          <Typography
            variant="Body1SemiBold"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {remainingDays} days remaining in free trial
          </Typography>
        )}
      </Stack>

      <Stack direction="column" alignItems="stretch" marginBottom="6px">
        <Button
          variant="contained"
          color="inherit"
          sx={{ marginBottom: '16px' }}
          onClick={onBackClick}
        >
          Back
        </Button>
        <Button
          variant="text"
          color="secondary"
          onClick={onFreeTrialCancelClick}
          sx={{ textTransform: 'capitalize' }}
        >
          Cancel Subscription
        </Button>
      </Stack>
    </PopUp>
  );
}
export default CancleSubscription;
