import { Button, Stack, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { track } from '@amplitude/analytics-browser';
import { amplitudeEvents } from 'configs/amplitude';
import { ConfigContext } from 'context';
import { useContext, useEffect, useState } from 'react';
import { checkFreeTrialEligibility } from 'services/api/subscriptionApi';
import { getLocalStorageItem } from 'utils/commonFunctions';
import CalenderIcon from 'assets/freeTrial/calendar.svg';
import AIIcon from 'assets/freeTrial/AIIcon.svg';
import HomeIcon from 'assets/freeTrial/home.svg';

const Image = styled.img<{
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.borderRadius || 0}px;
`;

function Features() {
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isUpgrade, deviceId } = location.state || {};
  const config = useContext(ConfigContext);
  const LivingRoomImg = config.freeTrialLivingRoom;
  const PersonDetectedImg = config.freeTrialPersonDetected;
  const CriticalAlertImg = config.freeTrialCriticalAlerts;

  const userId = getLocalStorageItem('userId');

  useEffect(() => {
    // Disable scroll restoration to manually control scroll behavior
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    checkFreeTrialEligibility().then((userFreeTrial) => {
      setIsFreeTrial(userFreeTrial.data.trial);

      if (userFreeTrial.data.trial && userId) {
        track(amplitudeEvents.TRIAL_INFO, { userId });
      }
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack>
      <Stack>
        <Typography variant="h2" textAlign="center" marginBottom="28px">
          Try Our Premium Security Plan
        </Typography>
      </Stack>
      <Image
        src={LivingRoomImg}
        width={isMobile ? `${window.innerWidth - 40}` : '335'}
        height="240"
        style={{ alignSelf: 'center', maxWidth: '100%', borderRadius: '24px' }}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: '12px',
          margin: '16px 0px'
        }}
      >
        <Image
          src={CalenderIcon}
          width="30px"
          height="30px"
          style={{ maxWidth: '100%' }}
        />
        <Typography
          variant="Body1"
          color="text.secondary"
          sx={{ textAlign: 'center' }}
        >
          30 day event video storage.
          <br />
          Securely stored and accessible anytime
        </Typography>
      </div>

      <Image
        src={PersonDetectedImg}
        width={isMobile ? `${window.innerWidth - 40}` : '368'}
        height="280"
        style={{ alignSelf: 'center', maxWidth: '100%', borderRadius: '24px' }}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: '12px',
          margin: '16px 0px'
        }}
      >
        <Image
          src={AIIcon}
          width="30px"
          height="30px"
          style={{ maxWidth: '100%' }}
        />
        <Typography
          variant="Body1"
          color="text.secondary"
          sx={{ textAlign: 'center' }}
        >
          AI detections reduce false alerts and
          <br />
          instant descriptive alerts
        </Typography>
      </div>

      <Image
        src={CriticalAlertImg}
        width={isMobile ? `${window.innerWidth - 40}` : '335'}
        height="240"
        style={{ alignSelf: 'center', maxWidth: '100%' }}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: '12px',
          margin: '16px 0px'
        }}
      >
        <Image
          src={HomeIcon}
          width="30px"
          height="30px"
          style={{ maxWidth: '100%' }}
        />
        <Typography
          variant="Body1"
          color="text.secondary"
          sx={{ textAlign: 'center' }}
        >
          Professional Monitoring using just the <br />
          camera. Alarms video verified by a<br /> professional before police
          dispatch.
        </Typography>
      </div>

      <Stack pt={4} gap={2} pb={4}>
        {isFreeTrial ? (
          <>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{
                width: isMobile ? `${window.innerWidth - 40}px` : '335px',
                textTransform: 'capitalize',
                alignSelf: 'center'
              }}
              onClick={() => {
                track(amplitudeEvents.TRIAL_INITIATED, {
                  userId
                });
                navigate('/buySubscription', {
                  replace: true,
                  state: {
                    isFreeTrial,
                    isUpgrade: isUpgrade || false,
                    deviceId
                  }
                });
              }}
            >
              Start Free Trial
            </Button>
            <Button
              size="large"
              variant="text"
              color="secondary"
              style={{
                width: isMobile ? `${window.innerWidth - 40}px` : '335px',
                textTransform: 'capitalize',
                alignSelf: 'center',
                border: 'none'
              }}
              onClick={() =>
                navigate('/whySkipTrial', {
                  state: {
                    isFreeTrial,
                    deviceId
                  }
                })
              }
            >
              Skip Free Trial
            </Button>
          </>
        ) : (
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={{
              width: isMobile ? `${window.innerWidth - 40}px` : '335px',
              textTransform: 'capitalize',
              alignSelf: 'center'
            }}
            onClick={() =>
              navigate('/buySubscription', {
                replace: true,
                state: {
                  isFreeTrial,
                  isUpgrade: isUpgrade || false,
                  deviceId
                }
              })
            }
          >
            Buy Plan
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
export default Features;
