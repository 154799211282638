import { ReactComponent as PaymentFailedIcon } from 'assets/payment-failed-icon.svg';
import { Stack, Button, Typography, Box } from '@mui/material';
import PopUp from 'components/PopUp';

interface TProps__StripePaymentFailed {
  showStripePaymentFailedPopUp: boolean;
  handlePaymentFailedTryAgainClick: () => void;
  code?: string;
  message?: string;
}

function StripePaymentFailed(props: TProps__StripePaymentFailed) {
  const {
    handlePaymentFailedTryAgainClick,
    showStripePaymentFailedPopUp,
    code,
    message
  } = props;
  const codeMapping =
    code === 'expired_card' ? 'Expired Card' : 'Failed Payment';
  return (
    <PopUp isOpen={showStripePaymentFailedPopUp}>
      <>
        <Stack direction="column" alignItems="center" marginBottom="30px">
          <Stack paddingBottom="12px">
            <PaymentFailedIcon />
          </Stack>
          <Typography variant="h2" paddingBottom="12px">
            {codeMapping}
          </Typography>
          <Box textAlign="center">
            <Typography variant="h6">{message}</Typography>
          </Box>
        </Stack>
        <Stack direction="column" alignItems="Stretch" marginBottom="6px">
          <Button
            variant="contained"
            color="inherit"
            sx={{
              marginBottom: '16px'
            }}
            onClick={handlePaymentFailedTryAgainClick}
          >
            Try Again
          </Button>
        </Stack>
      </>
    </PopUp>
  );
}
export default StripePaymentFailed;
