import { TDevice } from 'typings';

export type TSpaceResponseList = {
  items: TSpaceResponseData[];
  total_count: number;
  next_page: string;
};

export type TSpaceResponseData = {
  address: TAddress;
  id: string;
  name: string;
  timezone: string;
  owner?: string;
  subscription_details: TSubscriptionDetails;
};

export type TSpaceState = {
  spaceData: TSpaceResponseList;
  isLoading: boolean;
};

export type TAddress = {
  city: string;
  country: string;
  postal_code: string;
  state: string;
  street: string;
};

export type TSubscriptionDetails = {
  sku_id: string;
  devices: TDevice[];
  sku_name: string;
};

export type TSpaceResponseDataV2 = {
  address: TAddress;
  id: string;
  name: string;
  timezone: string;
  owner?: string;
  role?: string;
  subscription_details: TSubscriptionDetails;
  space_user_map_id: string;
};

export enum SpaceRole {
  OWNER = 'Owner'
}
