/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Stack } from '@mui/material';
import axios from 'axios';
import { stripeOptions, stripePromise } from 'configs/stripeConfig';
import { TProps_PaymentScreen } from 'typings';
import { endpoints } from 'services/api/endpoints';
import StripeForm from './StripeForm';

function PaymentScreenDemo(props: TProps_PaymentScreen) {
  const { submitPurchaseRequest, amount, isUpgrade, isFreeTrial } = props;
  const [options, setOptions] = useState(stripeOptions);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .post(endpoints.setupIntentUrl)
      .then((res) => {
        const { client_secret } = res.data;
        setOptions({ ...stripeOptions, clientSecret: client_secret });
        setLoading(false);
      })
      .catch((err) => {
        console.log('stripesetup err', err);
        setLoading(false);
      });
  }, []);

  if (loading) return <h1>Loading...</h1>;
  return (
    <Stack sx={{ m: '0 5px' }}>
      <Elements stripe={stripePromise} options={options}>
        <StripeForm
          amount={amount}
          isUpgrade={isUpgrade}
          isFreeTrial={isFreeTrial}
          submitPurchaseRequest={submitPurchaseRequest}
        />
      </Elements>
    </Stack>
  );
}

export default PaymentScreenDemo;
